
<template>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-lg-8 col-12 mt-5">
        <h2>Пробный расчет паттерна</h2>
        <label for="pattern" class="form-label">{{ $t('message.paletteSelect') }}</label>
        <div class="input-group mb-3">
          <label class="input-group-text" for="palette">{{ $t('message.palette') }}</label>
          <select
            v-model="palette"
            class="form-select"
            id="palette">
              <option selected value="9">9</option>
              <option value="12">12</option>
              <option value="13">13</option>
          </select>
        </div>
        <label for="whishphrase" class="form-label">{{ $t('message.wishPhrase') }}</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="payload"
            id="whishphrase"
            v-bind:placeholder="$t('message.wishPhrase')"
            @input="resetForm">
          <button
            class="btn btn-outline-primary"
            :disabled="btnIsDisable"
            type="button"
            id="button-submit"
            @click="submitForm()">
              {{ $t('message.go') }}
            </button>
        </div>
        <div class="mb-3">
          <div class="mb-3" style="overflow-x: auto;">
            <label for="calcres" class="form-label">{{ $t('message.calcresult') }}</label>
            <table
              v-if="isCalculated"
              class="table"
              id="legend"
              style="table-layout: fixed; text-align: center"
            >
              <tbody>
                <tr>
                  <template v-for="(item, index) in payload" v-bind:key="index">
                    <td v-if="payloadRegex.test(item)" style="width: 25px; min-width: 25px;">
                      {{ item }}
                    </td>
                  </template>
                </tr>

                <tr
                  v-for="(item, index) in result"
                  v-bind:key="index"
                >
                  <td v-for="(elem, indexj) in item" v-bind:key="indexj" style="width: 25px; min-width: 25px;">
                    {{ elem }}
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
        <div class="input-group mb-3">
          <button
              type="button"
              class="btn btn-primary"
              @click="exportToCSV">Выгрузить в CSV</button>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
  import axios from "axios";
  const API_URL =
    typeof import.meta.env.VITE_ROOTAPI === "undefined"
      ? "http://service:8000"
      : import.meta.env.VITE_ROOTAPI;

  import { useAuthStore } from "@/stores/user";
  import { inject } from 'vue'

  export default {
    name: "SignInView",

    setup() {
      const userStore = useAuthStore();
      const payloadRegex = inject('payloadRegex');
      return { payloadRegex, userStore };
    },

    data() {
      return {
        signInDetails: {
          username: "",
          password: "",
        },
        payload: "",
        mainMatrix: "",
        result: "",
        palette: "",
        isCalculated: false,
      };
    },
    computed: {
      btnIsDisable() {
        return this.payload.length == "" && this.palette == "";
      }
    },

    methods: {
      submitForm() {
        axios
          .get(`${API_URL}/draw/api/v1/free/democalc/`, {
            params: {
              q: this.payload,
            },
          })
          .then((response) => response.data)
          .then((data) => {
            this.result = data['main_matrix'][this.palette];
            this.isCalculated = true;
          });
      },
      resetForm() {
        this.result = "";
        this.isCalculated = false;
      },
      exportToCSV() {
        // Get the table element
        const table = this.$el.querySelector('table');

        // Initialize empty CSV string
        let csvString = '';

        // Loop through the rows and columns of the table
        for (let i = 0; i < table.rows.length; i++) {
          const rowData = [];
          for (let j = 0; j < table.rows[i].cells.length; j++) {
            rowData.push(table.rows[i].cells[j].innerText);
          }
          csvString += rowData.join(',') + '\n';
        }

        // Create a Blob object and create a download link
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        // Set link's attributes
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'table_data.csv');

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger a click on the link to start the download
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
      },
    },
  };
  </script>
