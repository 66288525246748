<template>
    <div class="policy-page">
        <div class="container">
            <div class="row">
                <div class="col-12">
                <h1>Политика обработки персональных данных</h1>
                
                Общие положения
                
                <p></p>1.1 Данная политика обработки персональных данных (далее - Политика) разработана в соответствии с Федеральным законом от 27.07.2006 №152-ФЗ "О персональных данных" (далее - Закон о персональных данных) и определяет порядок обработки и защиты персональных данных, которые собираются и обрабатываются сайтом artmandala.live (далее - сайт).
                
                <p></p>1.2 Персональные данные пользователей собираются и обрабатываются Компанией на условиях, определенных настоящей Политикой.
                
                    Сбор и обработка персональных данных
                
                <p></p>2.1 Компания осуществляет сбор персональных данных пользователей в целях исполнения договоров с ними, а также для обеспечения функционирования сайта/платформы/приложения/услуги (далее - Сервис).
                
                <p></p>2.2 Компания собирает следующие персональные данные:
                
                    Фамилия и имя пользователя;
                    Адрес электронной почты;
                    Платежные данные (при необходимости).
                
                <p></p>2.3 Сбор персональных данных осуществляется путем заполнения пользователем соответствующих форм на Сервисе.
                
                <p></p>2.4 Пользователь дает свое согласие на обработку своих персональных данных, предоставленных Компании, путем акцепта данной Политики при регистрации на Сервисе.
                
                    Цели обработки персональных данных
                
                <p></p>3.1 Компания обрабатывает персональные данные пользователей в следующих целях:
                
                    Регистрация и обслуживание пользовательской учетной записи;
                    Предоставление доступа к функционалу Сервиса;
                    Контактирование с пользователем по вопросам, касающимся предоставленных услуг;
                    Обработка платежей и выполнение финансовых транзакций (при необходимости).
                
                    Сроки обработки персональных данных
                
                <p></p>4.1 Персональные данные пользователей хранятся на протяжении всего срока использования Сервиса, а также в соответствии с требованиями действующего законодательства РФ.
                
                    Защита персональных данных
                
                <p></p>5.1 Компания обеспечивает защиту персональных данных пользователей от несанкционированного доступа, утраты, разрушения, блокирования, копирования, распространения и других неправомерных действий третьих лиц.
                
                    Порядок доступа к персональным данным
                
                <p></p>6.1 Доступ к персональным данным пользователей имеют только сотрудники Компании, которым это необходимо для выполнения своих должностных обязанностей.
                
                    Изменения в Политике
                
                <p></p>7.1 Компания оставляет за собой право вносить изменения в настоящую Политику. Изменения вступают в силу с момента их публикации на Сервисе.
                
                    Контактная информация
                
                <p></p>8.1 В случае возникновения вопросов, связанных с обработкой персональных данных, пользователи могут обратиться к администрации Компании по следующему адресу: info@artmandala.live.
                
                Дата вступления в силу: 01.01.2023
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PolicyPage',
    // Здесь могут быть дополнительные опции компонента
  }
  </script>
  