<template>
    <div class="container mx-auto px-4 py-8">
      <!-- Hero Section -->
      <div class="relative mb-12">
        <div class="bg-gray-200 h-64 md:h-96 w-full">
            <img src="@/assets/images/article-hero.png" alt="Мандала Сети Света" class="w-full">
        </div> <!-- Placeholder for hero image -->
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-80 p-4 rounded-lg text-center">
          <h1 class="text-xl md:text-2xl lg:text-3xl font-bold mb-2">
            Отличие методики «Мандалы Сети Света»
          </h1>
          <p class="text-sm md:text-base lg:text-lg">
            от других арт-терапевтических техник
          </p>
        </div>
      </div>

      <!-- Classical Mandala Section -->
      <section class="mb-12 mt-36">
        <h2 class="text-2xl font-bold mb-4 mt-36">Классическая Мандала</h2>
        <div class="flex flex-col md:flex-row gap-8">
          <div class="md:w-1/2">
            <p class="mb-4">Терапия - эффективная и при этом увлекательное лечение (терапия) посредством рисования сакральных кругов — мандал.</p>
            <p class="mb-4">Она помогает обрести душевное спокойствие, снять эмоциональное и ментальное утомление, стресс и бессонницу(вот не особенно я не верила), справиться с депрессией.</p>
            <p class="mb-4">Рисование мандал одновременно:</p>
            <ul class="list-disc list-inside mb-4">
              <li>позволяет понять себя и увидеть со стороны целостную картину своей проблемы;</li>
              <li>лечит негативные состояния в процессе рисования.</li>
            </ul>
            <a href="/#sets">
                <button class="bg-yellow-400 text-black px-6 py-3  font-semibold">К бесплатным наборам</button>
            </a>
          </div>
          <div class="md:w-1/2 bg-gray-200 h-64 md:h-auto">
            <img src="@/assets/images/article-girl.png" alt="Классическая мандала" class="w-full">
          </div> <!-- Placeholder for mandala image -->
        </div>
      </section>

      <!-- Fibonacci Principle Section -->
      <section class="mb-12">

        <div class="flex flex-col md:flex-row gap-8">
          <div class="md:w-1/2 bg-gray-200 h-64 md:h-auto">
            <img src="@/assets/images/article-draw.png" alt="Классическая мандала" class="w-full">
          </div> <!-- Placeholder for Fibonacci image -->
          <div class="md:w-1/2">
            <h2 class="text-2xl font-bold mb-4">Принцип Фибоначчи</h2>
            <p class="mb-4">Каждая буква в фразе соответствует определенной цифре. По методике, основанной на последовательности Фибоначчи, считается общее значение всех пересечений чисел в мандале. Помимо обычных цифр в мандале, используются «числа Фибоначчи» (наиболее значимые элементы, это вопрос, который выводится для него и самый большой и защищающий цвет в мандале)</p>
            <h3 class="font-semibold mb-2">Запросы или намерения</h3>
            <p class="mb-4">Особенность метода заключается в том, что в мандалу с помощью различных способов расчета зашифровывается фраза (слово), отражающее запрос человека для проработки.</p>
            <div class="flex flex-wrap gap-2 mb-4">
              <span class="bg-yellow-200 px-3 py-1 rounded-full text-sm">Деньги</span>
              <span class="bg-yellow-200 px-3 py-1 rounded-full text-sm">Карьера</span>
              <span class="bg-yellow-200 px-3 py-1 rounded-full text-sm">Любовь</span>
              <span class="bg-yellow-200 px-3 py-1 rounded-full text-sm">Здоровье</span>
            </div>
            <a href="/#sets">
                <button class="bg-yellow-400 text-black px-6 py-3  font-semibold">К бесплатным наборам</button>
            </a>
          </div>
        </div>
      </section>

      <!--section class="mb-12">
        <h2 class="text-2xl font-bold mb-4">Мандалы Сети Света</h2>
        <p class="mb-4">Они могут быть различных геометрических форм: квадратные, треугольные, восьмиугольные и другие. Мандалы Сети Света сочетают в себе два эффективных элемента арт-терапии</p>
        <div class="grid md:grid-cols-2 gap-8">
          <div>
            <h3 class="font-semibold mb-2">Матричная (цифровая) мандала</h3>
            <p>это графическое изображение в виде квадрата или иной геометрической фигуры, состоящее из ячеек, соответствующих цифрам числа Фибоначчи и раскрашенных в определенные цвета</p>
            <button class="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition">К бесплатным наборам</button>
          </div>
          <div>
            <h3 class="font-semibold mb-2">Точечная мандала</h3>
            <p>Создается на основании матричной мандалы. По методике рисования точечных мандал, человек наносит цветные точки на белый лист бумаги в соответствии с цветами интуитивно выбранной матрицы, человек переносит ее белый лист и раскрашивает точками, выбирая цвета по своему желанию.</p>
            <button class="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition">Заказать индивидуальный набор</button>
          </div>
        </div>
      </section-->

      <!-- Call to Action Section -->
      <section class="bg-gray-100 p-8 rounded-lg text-center">
        <div class="max-w-2xl mx-auto">
          <div class="bg-gray-200 h-32 w-32 mx-auto mb-4 rounded-full">
            <img src="@/assets/images/article-58.png" alt="Классическая мандала" class="w-full">
          </div> <!-- Placeholder for mandala icon -->
          <h2 class="text-xl md:text-2xl font-bold mb-4">Хочется научиться создавать мандалы, решающие ваши проблемы и исполняющие желания?</h2>
          <p class="mb-6">Записывайтесь на курс обучения методике создания мандал.</p>
          <a href="https://t.me/OlgaChamina">
            <button class="bg-yellow-400 text-black px-6 py-3 rounded-full text-lg font-semibold hover:bg-yellow-500 transition">Записаться</button>
          </a>
        </div>
      </section>
    </div>
  </template>

<script setup>
import { useHead } from 'unhead'
useHead({
  title: 'Мандала Арт терапия онлайн',
  meta: [
    { name: 'description', content: 'Терапия рисунком онлайн приложение. Нарисовать онлайн мандалу' },
  ],
})
</script>
