<template>
    <div class="min-h-[60vh] bg-gradient-to-br from-purple-50 via-indigo-50 to-purple-50 py-12">
      <div class="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <div class="bg-white rounded-xl p-8 shadow-lg">
          <div class="mb-6">
            <div class="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100">
              <svg class="h-10 w-10 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </div>
          </div>

          <h2 class="text-3xl font-bold text-gray-900 mb-4">
            {{ $t('message.paymentFailTitle') }}
          </h2>

          <p class="text-lg text-gray-600 mb-8">
            {{ $t('message.paymentFailMessage') }}
          </p>

          <div class="space-y-4">
            <router-link
              to="/payment"
              class="inline-block w-full sm:w-auto px-8 py-3 text-center font-semibold rounded-lg bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-500 hover:to-indigo-500 shadow-lg hover:shadow-xl transition-all duration-300"
            >
              {{ $t('message.tryAgain') }}
            </router-link>

            <div class="mt-4">
              <a
                href="mailto:support@example.com"
                class="text-purple-600 hover:text-purple-500 font-medium"
              >
                {{ $t('message.contactSupport') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'PaymentFail'
  })
  </script>
