<template>
  <div class="min-h-screen bg-gray-50 py-8">
    <!-- Print Preview Section -->
    <div class="max-w-4xl mx-auto space-y-8">
      <!-- Colored Mandala -->
      <div v-if="printDefaultMandala === 'true'" class="pagebreak">
        <div class="bg-white rounded-lg shadow-sm p-8 text-center">
          <h2 class="payload text-xl md:text-2xl text-gray-800 mb-6">{{ payload }}</h2>
          <img
            v-if="b64image_color"
            :src="'data:image/jpeg;base64,' + b64image_color"
            class="printEl mx-auto max-w-full h-auto"
            alt="Colored Mandala"
          />
        </div>
      </div>

      <!-- Black and White with Numbers -->
      <div v-if="printOnlyDigits === 'true'" class="pagebreak">
        <div class="digits bg-white rounded-lg shadow-sm p-8 text-center">
          <h2 class="payload text-xl md:text-2xl text-gray-800 mb-6">{{ payload }}</h2>
          <img
            v-if="printOnlyDigits"
            :src="'data:image/jpeg;base64,' + b64image_bw"
            class="mx-auto max-w-full h-auto"
            alt="Black and White Mandala"
          />
        </div>
      </div>

      <!-- Black and White with Dots -->
      <div v-if="printOnlyDots === 'true'" class="pagebreak">
        <div class="bg-white rounded-lg shadow-sm p-8 text-center">
          <h2 class="payload text-xl md:text-2xl text-gray-800 mb-6">{{ payload }}</h2>
          <img
            v-if="b64image_bw_dots"
            :src="'data:image/jpeg;base64,' + b64image_bw_dots"
            class="mx-auto max-w-full h-auto"
            alt="Dotted Mandala"
          />
        </div>
      </div>

      <!-- Individual Pattern Pages -->
      <div v-if="printDotsByNumbers === 'true'" class="pagebreak">
        <template v-for="index in patternSorted" :key="index">
          <div class="pagebreak">
            <div class="bg-white rounded-lg shadow-sm p-8 text-center">
              <h2 class="payload text-xl md:text-2xl text-gray-800 mb-6">
                {{ payload }} №{{ index }}
              </h2>
              <img
                v-if="b64images_dotted[index]"
                :src="b64images_dotted[index]"
                class="mx-auto max-w-full h-auto"
                alt="Pattern Mandala"
              />
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Print Dialog Modal -->
    <TransitionRoot appear :show="modaltogle" as="template">
      <Dialog as="div" @close="closemodal" class="relative z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                  {{ $t("message.print") }}
                </DialogTitle>

                <div class="mt-4">
                  <p class="text-sm text-gray-500">
                    {{ $t("message.printHint") }}
                  </p>
                </div>

                <div class="mt-6 flex justify-end">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    @click="closemodal"
                  >
                    {{ $t("message.close") }}
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { onBeforeUnmount } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue';
import axiosInstance from '@/axios/axios';

const API_URL = import.meta.env.VITE_ROOTAPI || "http://service:8000";

function encodeQueryData(data) {
  return Object.entries(data)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

export default {
  name: "Print-comp",
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionRoot,
    TransitionChild
  },

  data() {
    return {
      payload: "",
      b64image_color: "",
      b64image_bw: "",
      b64image_bw_dots: "",
      b64images_dotted: [],
      main_matrix: [],
      shapeSelected: "",
      shapeSelectedId: "",
      patternSymbols: "",
      patternUnfiltered: "",
      paletteSelected: 9,
      firstMethodSelected: "",
      additionalMethodSelected: "",
      pattern: "",
      printDefaultMandala: false,
      printOnlyDigits: false,
      printOnlyDots: false,
      modaltogle: false,
      patternLenSelected: 0,
      patternSorted: [],
      draw_url: "",
    };
  },

  setup() {
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    });
  },

  mounted() {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    this.parseQueryString();
    this.getShapeData()
    this.loadImages();
    this.unColorizeShape();
    this.colorizeShape();
    this.DotShape();
  },
  methods: {
    getShapeData() {
      axiosInstance
        .get(`${API_URL}/draw/api/v1/free/shapesdata/`, {
          params: {
            shape: this.shapeSelected,
            q: this.payload,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          this.main_matrix = data.main_matrix[this.patternLenSelected - 1];
          this.patternUnfiltered =
          this.main_matrix[this.paletteSelected][
            this.main_matrix[this.paletteSelected].length -
              this.patternLenSelected
          ];
        });
    },
    async closemodal() {
      this.modaltogle = false;

      // Wait for modal transition to complete (300ms) plus a small buffer
      await new Promise(resolve => setTimeout(resolve, 400));

      // Show print dialog
      this.printDownload();
    },
    beforeUnloadHandler(event) {
      this.closePrintDialog();
    },

    closePrintDialog() {
      if (window.matchMedia('print').matches) {
        window.close();
      }
    },

    parseQueryString() {
      const required = [
        "payload",
        "shapeSelected",
        "shapeSelectedId",
        "patternLenSelected",
        "paletteSelected",
        "firstMethodSelected",
        "additionalMethodSelected",
        "printDefaultMandala",
        "printOnlyDigits",
        "printOnlyDots",
        "printDotsByNumbers",
        "pattern"
      ];

      required.forEach(param => {
        if (this.$route.query[param] !== undefined) {
          this[param] = this.$route.query[param];
        }
      });

      this.draw_url = `${API_URL}/draw/api/v1/premium/shape/${this.shapeSelected}?`;
      this.patternSorted = this.pattern.split(',').map(Number).sort((a, b) => a - b);
    },

    async sendDrawRequest(data) {
      try {
        const url = this.draw_url + encodeQueryData(data);
        const response = await axiosInstance.get(url);
        return response?.data?.pic || "";
      } catch (error) {
        console.error('Draw request failed:', error);
        return "";
      }
    },

    async colorizeShape() {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        colorize: 1,
      };
      this.b64image_color = await this.sendDrawRequest(data);
    },

    async unColorizeShape() {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        colorize: 0,
        filter: Array.from({length: 20}, (_, i) => i + 1).join(',')
      };
      const img = await this.sendDrawRequest(data);
      this.b64image_bw = img;
    },

    async filterShape(index) {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        colorize: 0,
        num_to_dot: 1,
        filter: index,
      };
      return await this.sendDrawRequest(data);
    },
    async DotShape() {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        colorize: 0,
        num_to_dot: 1,
        filter: this.pattern
      };
      const img = await this.sendDrawRequest(data);
      this.b64image_bw_dots = img;
    },
    async loadImages() {
      try {
            const promises = [];
            for (let i = 0; i < this.patternSorted.length; i++) {
                let ind = this.patternSorted[i];
                promises.push(this.filterShape(ind));
            }
            const results = await Promise.all(promises);

            results.forEach((result, i) => {
                let ind = this.patternSorted[i];
                this.b64images_dotted[ind] = 'data:image/png;base64,' + result;
            });

            await new Promise(resolve => setTimeout(resolve, 3000));
            this.openModal();
        } catch (error) {
              console.error('Error fetching images:', error);
        }
    },

    printDownload() {
      setTimeout(() => window.print(), 0);
    },

    openModal() {
      this.modaltogle = true;
    },
  },
};
</script>

<style>
@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }

  .printEl {
    width: 800px;
    height: 800px;
    max-width: 800px !important;
  }
}

.payload {
  font-size: clamp(18px, 2vw, 24px);
}
</style>
