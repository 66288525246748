<script>
import axiosInstance from '@/axios/axios';
import { inject } from 'vue';
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  return ret.join("&");
}


const API_URL =
  typeof import.meta.env.VITE_ROOTAPI === "undefined"
    ? "http://service:8000"
    : import.meta.env.VITE_ROOTAPI;

export default {
  name: "Wish-Phrase",
  setup() {
      const payloadRegex = inject('payloadRegex');
      const payloadRegexG = inject('payloadRegexG');
      return { payloadRegex, payloadRegexG};
  },
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    ExclamationTriangleIcon
  },
  data() {
    return {
      commondata: { menu: { shapes: {} } },
      b64image: "",
      steps: {
        1: true,
        2: false,
        3: false,
      },
      payload: "",
      patternsList: 0, //Lists of available patterns
      shapeSelected: "",
      shapeSelectedId: "",
      patternSymbols: "",
      patternLenSelected: 1,
      paletteSelected: 9,
      firstMethodSelected: "",
      additionalMethodSelected: "",
      main_matrix: { 1: [1] },
      patternValue: "%",
      pattern: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20",
      patternUnfiltered: "",
      printDefaultMandala: "true",
      printOnlyDigits: false,
      printOnlyDots: "false",
      printDotsByNumbers: "false",
      mandalaForm: "colorized",
      sectorLength: 8,
      open: false,
      print_open: false
    };
  },
  computed: {
    computedLocale() {
      return this.$i18n.locale.trim();
    },
    fakePayload() {
      return this.payload.replace(!this.payloadRegex, '');
    },
    baseUrl() {
      return window.location.origin + this.$route.path;
    },
    firstMethod: function () {
      if (this.commondata["methods"]) {
        return this.commondata["methods"];
      } else {
        return "";
      }
    },
    additionalMethod: function () {
      if (this.commondata["methods"]) {
        var methods = this.commondata["methods"];
        return methods[this.firstMethodSelected].additional_methods;
      } else {
        return {};
      }
    },
    firstMethodLength: function () {
      if (this.commondata["methods"]) {
        var methods = this.commondata["methods"];
        if (Object.keys(methods).length) {
          if (Object.keys(methods[this.firstMethodSelected]).length) {
            return Object.keys(methods[this.firstMethodSelected]).length;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    additionalMethodLength: function () {
      if (this.commondata["methods"]) {
        var methods = this.commondata["methods"];
        if (Object.keys(methods).length) {
          if (methods[this.firstMethodSelected].additional_methods) {
            return Object.keys(
              methods[this.firstMethodSelected].additional_methods
            ).length;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    paletteRendered: function () {
      var result = {};
      if (this.patternSymbols) {
        var pattern_first_symbol =
          this.patternSymbols[0] < 2 ? 1 : this.patternSymbols[0];
        var temp_palette =
          this.commondata["palettes"][this.paletteSelected][
            pattern_first_symbol
          ];
        result[1] = {};
        result[2] = {};
        for (let i = 1; i <= 7; i++) {
          result[1][i] = {};
          result[1][i]["id"] = i;
          result[1][i]["hex"] = temp_palette[i];
        }
        for (let i = 8; i <= this.paletteSelected; i++) {
          result[2][i] = {};
          result[2][i]["id"] = i;
          result[2][i]["hex"] = temp_palette[i];
        }
        return result;
      }
      return result;
    },
    mainMatrixRendered: function () {
      let result = [];
      if (Object.keys(this.main_matrix).length > 1) {
        if (this.main_matrix[this.paletteSelected].length > 0) {
          for (
            let i = 0;
            i < this.main_matrix[this.paletteSelected].length;
            i++
          ) {
            let row = [];
            if (Array.isArray(this.main_matrix[this.paletteSelected][i])) {
              for (
                let j = 0;
                j < this.main_matrix[this.paletteSelected].length;
                j++
              ) {
                if (j < this.main_matrix[this.paletteSelected][i].length) {
                  row[j] = this.main_matrix[this.paletteSelected][i][j];
                } else {
                  row[j] = "";
                }
              }
              result[i] = row;
            }
          }
          return result;
        } else {
          return result;
        }
      } else {
        return result;
      }
    },
    payloadFactLength: function() {
      let symbolRegExp = "";
      symbolRegExp = this.payloadRegexG ? this.payloadRegexG : /[0-9a-zA-Zа-яА-ЯЁё_]/g;
      const matches = Array.from(this.payload.matchAll(symbolRegExp));
      const count = matches.length;
      console.log(`The number of matches for '${symbolRegExp}' is: ${count}`);
      return count || 0;
    },
    showSectorLength: function() {
      return ["eight_diamond", "six_diamond", "stern" ].includes(this.shapeSelected) && this.firstMethodSelected[0] == '8'
    }
  },
  beforeCreate() {
    axiosInstance
      .get(`${API_URL}/draw/api/v1/free/commondata/`)
      .then((response) => (this.commondata = response.data));
  },
  methods: {
    gotoStep: function (step) {
      for (let k in this.steps) {
        if (k <= step) {
          this.steps[k] = true;
        } else {
          this.steps[k] = false;
        }
      }
    },
    buildQuerydata: function () {
      console.log(this.printOnlyDigits)
      const raw = this.$data;
      const allowed = [
        "payload",
        "shapeSelected",
        "shapeSelectedId",
        "pattern",
        "paletteSelected",
        "firstMethodSelected",
        "additionalMethodSelected",
        "patternLenSelected",
        "printDefaultMandala",
        "printOnlyDigits",
        "printDotsByNumbers",
        "printOnlyDots",
        "sectorLength",
      ];
      const filtered = Object.keys(raw)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {});
      return filtered;
    },
    gotoPrint() {
      let route = this.$router.resolve({
        path: "/print",
        query: this.buildQuerydata(),
      });
      window.open(route.href);
      this.print_open = false;
    },
    selectShapeId(e) {
      this.shapeSelectedId = e.id;
    },
    figureSelected() {
      this.gotoStep(2);
      this.draw_url = `${API_URL}/draw/api/v1/premium/shape/${this.shapeSelected}?`;
      if (typeof this.shapeSelected !== 'undefined') {
        axiosInstance
          .get(`${API_URL}/draw/api/v1/premium/shapesdata/`, {
            params: {
              shape: this.shapeSelected,
              q: this.payload,
            },
          })
          .then((response) => response.data)
          .then((data) => {
            this.commondata = Object.assign({}, this.commondata, data);
            this.firstMethodSelected = data.default_method;
            if (data.default_additional_method) {
              this.additionalMethodSelected = data.default_additional_method;
            }
            this.main_matrix = data.main_matrix;
            var min_val = 0;
            min_val = Math.min(
              this.payload.length,
              this.commondata["pattern_max_len"],
              this.payloadFactLength
            )
            ? Math.min(
                        this.payload.length,
                        this.commondata["pattern_max_len"],
                        this.payloadFactLength
                      )
            : 0;
            this.patternsList = Array.from(Array(min_val).keys());
            this.patternClick();
          });
      }
    },
    patternClick() {
      this.patternSymbols =
        this.commondata["main_matrix"][this.paletteSelected][
          this.commondata["main_matrix"][this.paletteSelected].length -
            this.patternLenSelected
        ];
      this.gotoStep(2);
    },
    sendDrawRequest(data) {
      let url = this.draw_url + encodeQueryData(data);
      return axiosInstance.get(url, this.sec_headers).then((response) => {
        (this.b64image = response.data.pic || ""),
          (this.pattern = response.data.pattern);
      });
    },
    processrequest() {
      console.log("processing request");
      switch (this.mandalaForm) {
        case "colorized":
          console.log("colorizing mandala");
          this.colorizeShape();
          break;
        case "digits":
          this.unColorizeShape();
          break;
        case "dots":
          this.filterShape();
          break;
      }
      this.gotoStep(3);
    },
    colorizeShape() {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        sector_length: this.sectorLength,
        colorize: 1,
      };
      this.sendDrawRequest(data);
    },
    unColorizeShape() {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        sector_length: this.sectorLength,
        colorize: 0,
        filter: "*",
      };
      this.sendDrawRequest(data);
    },
    filterShape() {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        sector_length: this.sectorLength,
        colorize: 0,
        num_to_dot: 1,
        filter: this.pattern,
      };
      this.sendDrawRequest(data);
    },
    phraseChange() {
      this.b64image = "";
      this.gotoStep(1);
      this.shapeSelected = "";
      this.pattern = "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20";
      this.firstMethodSelected = "";
      this.additionalMethodSelected = "";
      delete this.commondata["methods"];
    },
    figureChange() {
      this.b64image = "";
      this.gotoStep(1);
      this.pattern = "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20";
      this.firstMethodSelected = "";
      this.additionalMethodSelected = "";
      delete this.commondata["methods"];
      this.patternLenSelected = 1;
      this.paletteSelected = 9;
      this.figureSelected();
    },
    firstMethodSelect() {
      this.additionalMethodSelected = "";
    },
  },
};
</script>
<style scoped>
label.btn.btn-outline-primary {
  min-width: 45px;
}
.matrixCell {
 max-width: 30px;
}

</style>
<template>
<div class="container mx-auto px-4">
  <div class="grid grid-cols-1 md:grid-cols-8 gap-4">
    <div class="md:col-span-6">
        <img
          v-if="b64image != ''"
          v-bind:src="'data:image/jpeg;base64,' + b64image"
          class="img-fluid"
          alt="..."
        />
      </div>
      <div class="md:col-span-2">
        <div class="mb-3 step-1">
          <label for="payload" class="form-label">{{
            $t("message.wishPhrase")
          }}</label>
          <div class="mb-3">
            <textarea
              id="wishtext"
              v-model="payload"
              rows="4"
              class="border mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              @change="phraseChange"
              aria-describedby="wishhelp"
            ></textarea>
          </div>

          <div v-if="steps[1]">
            <label for="chooseShape" class="mt-4 block text-sm font-medium text-gray-700">{{ $t('message.chooseShape') }}</label>
            <select
              id="chooseShape"
              v-model="shapeSelected"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              aria-label="{{ $t('message.shapes') }}"
              :disabled="payload.length < 5"
              @change="figureChange"
            >
              <option v-for="(item, index) in commondata.menu.shapes[computedLocale]" :key="index" :value="item.name">
                {{ item.displayLabel }}
              </option>
            </select>

            <div v-if="firstMethodLength > 0" class="mt-4">
              <label for="methodOne" class="block text-sm font-medium text-gray-700">{{ $t('message.methodOne') }}</label>
              <select
                id="methodOne"
                v-model="firstMethodSelected"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                @change="firstMethodSelect"
              >
                <option v-for="(item, index) in firstMethod" :key="index" :value="index">
                  {{ item.method_label }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="additionalMethodLength > 0" class="mt-4">
            <label for="methodTwo" class="block text-sm font-medium text-gray-700">{{ $t("message.methodTwo") }}</label>
            <select
              id="methodTwo"
              v-model="additionalMethodSelected"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option
                v-for="(item, index) in additionalMethod"
                :key="index"
                :value="item.method_additional_name"
              >
                {{ item.method_additional_label }}
              </option>
            </select>
          </div>
          <div class="step-2" v-if="steps[2]">
            <div class="mb-3">
              <label for="payload" class="form-label">{{
                $t("message.patternLength")
              }}</label>
              <div></div>
              <div
                class="btn-toolbar"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
              <div class="inline-flex space-x-2" role="group" aria-label="First group">
                <div class="grid grid-cols-7 rounded-xl ">
                  <template
                    v-for="patternValue in patternsList"
                    v-bind:key="patternValue"
                  >
                  <template v-if="patternValue*1 <= 11">
                    <div>
                      <input type="radio" :id="'patternlen-' + (patternValue + 1)" name="brt-patternlen" :value="patternValue + 1" class="hidden peer" v-model="patternLenSelected" @change="patternClick($event)">
                      <label
                        :for="'patternlen-' + (patternValue + 1)"
                        :class="[
                          'inline-flex justify-center items-center py-2 min-w-10 text-sm text-white font-medium border border-gray-300 rounded-lg focus:ring-4 focus:ring-blue-300',
                          patternLenSelected === patternValue + 1
                            ? 'bg-blue-600 text-white'
                            : 'bg-blue-800 hover:bg-gray-100 dark:bg-white dark:text-black dark:hover:bg-gray-600 dark:hover:border-gray-600'
                        ]"
                      >
                        {{ patternValue + 1 }}
                      </label>
                    </div>
                  </template>
                </template>
                </div>
              </div>
              <div class="inline-flex space-x-2" role="group" aria-label="Pattern Length Selection">
                <div class="inline-flex space-x-2" role="group" aria-label="First group">
                  </div>
                <div class="inline-flex space-x-2" role="group" aria-label="Second group">
                  </div>
              </div>
            </div>

              <span
                id="wishhelp"
                v-if="patternSymbols != '' && this.steps[3]"
                class="form-text"
                >{{ $t("message.pattern") }}(<a
                  @click="open = true"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#mainMatrixModal"
                  data-modal-target="mainMatrixModal"
                  data-modal-toggle="mainMatrixModal"
                  >{{ $t("message.seeAll") }}</a
                >):</span
              >
              <span
                id="wishhelp"
                v-if="patternSymbols != '' && this.steps[3]"
                class="form-text"
                >{{ patternSymbols }}</span
              >
            </div>
            <div class="mb-3" v-if="showSectorLength">
              <label for="payload" class="form-label">{{
                $t("message.sectorLength")
              }}</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="sectorLength"
                  aria-describedby="wishhelp"
                  v-model="sectorLength"
                />
              </div>
            </div>
            <div class="pallete">
              <label for="payload" class="form-label">{{
                $t("message.paletteSelect")
              }}</label>
              <div></div>
              <div class="grid grid-cols-7 gap-2 rounded-xl">
                <div v-for="value in [9, 12, 13]" :key="value">
                  <input
                    type="radio"
                    :id="'btr-pal' + value"
                    name="btr-palette"
                    :value="value"
                    class="hidden peer"
                    v-model="paletteSelected"
                    @change="patternClick($event)"
                    autocomplete="off"
                  />
                  <label
                    :for="'btr-pal' + value"
                    :class="[
                          'inline-flex justify-center items-center py-2 min-w-10 text-sm text-white font-medium border border-gray-300 rounded-lg focus:ring-4 focus:ring-blue-300',
                          paletteSelected === value
                            ? 'bg-blue-600 text-white'
                            : 'bg-blue-800 hover:bg-gray-100 dark:bg-white dark:text-black dark:hover:bg-gray-600 dark:hover:border-gray-600'
                        ]"
                  >{{ value }}</label>

                </div>
              </div>
              <label for="legend" class="form-label">{{
                $t("message.paletteLegend")
              }}</label>
              <div></div>
              <table
                class="table"
                id="legend"
                style="table-layout: fixed; text-align: center"
              >
                <tbody>
                  <tr>
                    <td
                      v-for="color in paletteRendered[1]"
                      v-bind:key="color.id"
                      v-bind:style="{
                        backgroundColor: color.hex,
                        width: '35px',
                      }"
                    >
                      {{ color.id }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="color in paletteRendered[2]"
                      v-bind:key="color.id"
                      v-bind:style="{
                        backgroundColor: color.hex,
                        width: '35px',
                      }"
                    >
                      {{ color.id }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <label for="brngroup" class="form-label">{{
              $t("message.viewsCaption")
            }}</label>
            <br>
            <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic radio toggle button group">
              <div class="relative">
                <input
                  type="radio"
                  class="absolute w-full h-full opacity-0 z-10 cursor-pointer peer"
                  id="btnradio1"
                  value="colorized"
                  :checked="mandalaForm === 'colorized'"
                  @change="mandalaForm = 'colorized'"
                  name="btnradio"
                  autocomplete="off"
                />
                <label
                  for="btnradio1"
                  class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-00 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-white dark:border-gray-300  dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white peer-checked:bg-blue-600 peer-checked:text-white"
                >
                  {{ $t("message.colorizedCaption") }}
                </label>
              </div>

              <div class="relative">
                <input
                  type="radio"
                  class="absolute w-full h-full opacity-0 z-10 cursor-pointer peer"
                  id="btnradio2"
                  value="digits"
                  :checked="mandalaForm === 'digits'"
                  @change="mandalaForm = 'digits'"
                  name="btnradio"
                  autocomplete="off"
                />
                <label
                  for="btnradio2"
                  class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-00  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-white dark:border-gray-300  dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white peer-checked:bg-blue-600 peer-checked:text-white"
                >
                  {{ $t("message.digitsCaption") }}
                </label>
              </div>

              <div class="relative" v-if="steps[3]">
                <input
                  type="radio"
                  class="absolute w-full h-full opacity-0 z-10 cursor-pointer peer"
                  id="btnradio3"
                  value="dots"
                  :checked="mandalaForm === 'dots'"
                  @change="mandalaForm = 'dots'"
                  name="btnradio"
                  autocomplete="off"
                />
                <label
                  for="btnradio3"
                  class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-00 rounded-r-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-white dark:border-gray-300  dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white peer-checked:bg-blue-600 peer-checked:text-white"
                >
                  {{ $t("message.dotsCaption") }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="mb-3" v-if="b64image != '' && steps[3]">
          <button class="btn btn-primary btn-margin" @click="unColorizeShape()">
            {{ $t("message.showDigits") }}
          </button>
        </div> -->
        <div
          class="mb-3"
          v-if="b64image != '' && steps[3] && mandalaForm == 'dots'"
        >
          <div class="mb-4">
            <label for="mainMatrix" class="block mb-2 text-sm font-medium text-gray-700">
              {{ $t("message.yourMatrix") }}
            </label>
            <input
              type="text"
              id="mainMatrix"
              v-model="pattern"
              aria-describedby="wishhelp"
              class="w-full px-3 py-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic outlined example">
          <button
            v-if="steps[2]"
            type="button"
            class="px-4 py-2 text-sm font-medium text-blue-600 bg-white border border-blue-200 rounded-l-md hover:bg-blue-50 focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-600 transition-colors duration-200"
            @click="processrequest"
            id="btnGroupAddon1"
          >
            {{ $t("message.go") }}
          </button>
          <button
            v-if="b64image && steps[3]"
            type="button"
            class="px-4 py-2 text-sm font-medium text-blue-600 bg-white border border-blue-200 rounded-r-md hover:bg-blue-50 focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-600 transition-colors duration-200"
            role="link"
            @click="print_open = true"
          >
            {{ $t("message.print") }}
          </button>
        </div>
      </div>
    </div>
    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-10" @close="open = false" :open="open">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ $t("message.matrixOfPatterns") }}</DialogTitle>
                      <div class="mt-2">
                        <div class="overflow-x-auto">
                          <table v-if="1 != 0" class="table-fixed w-full text-center">
                            <tbody>
                              <tr>
                                <template v-for="(symbol, idx) in fakePayload" :key="idx">
                                  <td class="matrixCell px-4 py-2" v-show="payloadRegex.test(symbol)">
                                    {{ symbol }}
                                  </td>
                                </template>
                              </tr>
                              <tr v-for="(item, index) in mainMatrixRendered" :key="index">
                                <td class="matrixCell px-4 py-2" v-for="(elem, indexj) in item" :key="indexj">
                                  {{ elem }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="open = false" ref="cancelButtonRef">OK</button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <TransitionRoot as="template" :show="print_open">
      <Dialog as="div" :open="print_open" @close="print_open = false" class="relative z-10">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PrinterIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
                      {{ $t("message.printsCaption") }}
                    </DialogTitle>
                    <div class="mt-2 space-y-4">
                      <div class="flex items-center">
                        <input id="printDefaultMandala" type="checkbox" v-model="printDefaultMandala" :checked="printDefaultMandala"
                          class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" />
                        <label for="printDefaultMandala" class="ml-3 text-sm font-medium text-gray-700">
                          {{ $t("message.colorizedCaption") }}
                        </label>
                      </div>
                      <div class="flex items-center">
                        <input id="printOnlyDigits" v-model="printOnlyDigits" type="checkbox" :checked="printOnlyDigits"
                          class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" />
                        <label for="printOnlyDigits" class="ml-3 text-sm font-medium text-gray-700">
                          {{ $t("message.digitsCaption") }}
                        </label>
                      </div>
                      <div class="flex items-center">
                        <input id="printOnlyDots" type="checkbox" v-model="printOnlyDots" :checked="printOnlyDots"
                          class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" />
                        <label for="printOnlyDots" class="ml-3 text-sm font-medium text-gray-700">
                          {{ $t("message.dotsCaption") }}
                        </label>
                      </div>
                      <div class="flex items-center">
                        <input id="printDotsByNumbers" type="checkbox" v-model="printDotsByNumbers" :checked="printDotsByNumbers"
                          class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" />
                        <label for="printDotsByNumbers" class="ml-3 text-sm font-medium text-gray-700">
                          {{ $t("message.dotsByNumberCaption") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button @click="gotoPrint" type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto">
                  {{ $t("message.print") }}
                </button>
                <button @click="print_open = false" type="button"
                  class="mt-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto">
                  {{ $t("message.close") }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
    </TransitionRoot>
  </div>
</template>
