import { defineStore } from "pinia";
import axiosInstance from '@/axios/axios';

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    token: localStorage.getItem("token") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
    loading: false,
    error: null,
    plan: localStorage.getItem("plan") ? JSON.parse(localStorage.getItem("plan")) : null,
    isSubscriptionActive: localStorage.getItem("isSubscriptionActive") === 'true' || false
  }),

  getters: {
    isAuthenticated: (state) => !!state.token && !!state.user,
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getError: (state) => state.error,
    getRefreshToken: (state) => state.refreshToken,
    getSubscriptionStatus: (state) => state.isSubscriptionActive,
    getPlan: (state) => state.plan
  },

  actions: {
    async login(credentials) {
      this.loading = true;
      this.error = null;

      try {
        // 1. Get tokens
        const response = await axiosInstance.post("/api/auth/token/", credentials);

        if (!response.data.access || !response.data.refresh) {
          throw new Error("Invalid token response");
        }

        // 2. Store tokens
        this.setTokens(response.data.access, response.data.refresh);

        // 3. Get user data
        await this.fetchUserProfile();

        return true;
      } catch (error) {
        this.clearAuth();
        this.error = error.response?.data?.detail || "Authentication failed";
        return false;
      } finally {
        this.loading = false;
      }
    },

    async fetchUserProfile() {
      try {
        const response = await axiosInstance.get("/api/auth/userinfo/", {
          headers: { Authorization: `Bearer ${this.token}` }
        });

        if (!response.data) {
          throw new Error("No user data received");
        }

        this.setUser(response.data);
        return true;
      } catch (error) {
        this.clearAuth();
        throw new Error("Failed to fetch user profile");
      }
    },

    setTokens(accessToken, refreshToken) {
      this.token = accessToken;
      this.refreshToken = refreshToken;
      localStorage.setItem("token", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    },
    setToken(token) {
      this.token = token;
      localStorage.setItem("token", token);
      if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
      }
    },
    setUser(userData) {
      // Update user basic info
      this.user = {
        id: userData.id,
        username: userData.username,
        email: userData.email,
        firstName: userData.first_name,
        lastName: userData.last_name,
        avatar: userData.avatar,
        bio: userData.bio,
        location: userData.location,
        joinedDate: userData.joined_date
      };

      // Update subscription status
      this.isSubscriptionActive = userData.subscription_status?.is_active || false;
      console.log(this.isSubscriptionActive = userData.subscription_status?.is_active)
      // Update plan information if available
      if (userData.subscription_details) {
        this.plan = {
          id: userData.subscription_details?.plan_id,
          name: userData.subscription_details?.plan_name,
          expiryDate: userData.subscription_details?.expiry_date,
          isTrial: userData.subscription_details?.is_trial
        };
      } else {
        this.plan = null;
      }

      // Persist to localStorage
      localStorage.setItem("user", JSON.stringify(this.user));
      localStorage.setItem("isSubscriptionActive", String(this.isSubscriptionActive));
      localStorage.setItem("plan", this.plan ? JSON.stringify(this.plan) : null);
    },
    clearAuth() {
      this.token = null;
      this.refreshToken = null;
      this.user = null;
      this.error = null;
      this.plan = null;
      this.isSubscriptionActive = false;

      // Clear all related localStorage items
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
      localStorage.removeItem("plan");
      localStorage.removeItem("isSubscriptionActive");

      delete axiosInstance.defaults.headers.common['Authorization'];
    },

    async logout() {
      // Optional: Call logout endpoint if you have one
      // await axios.post("/api/auth/logout/");
      this.clearAuth();
    },

    async checkAuth() {
      if (!this.token || !this.user) {
        this.clearAuth();
        return false;
      }

      try {
        await this.fetchUserProfile();
        return true;
      } catch {
        return false;
      }
    }
  },
});
