<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useAuthStore } from '@/stores/user'
import axiosInstance from '@/axios/axios'

export default defineComponent({
  name: 'PaymentPage',

  data() {
    return {
      plans: [],
      isLoading: false,
      userInfo: {},
    }
  },

  computed: {
    userStore() {
      return useAuthStore()
    }
  },

  methods: {
    formatPrice(price) {
      return Number(price).toLocaleString('ru-RU')
    },

    isCurrentPlan(plan) {
      if (!this.userInfo.subscription_details) return false
      return this.userInfo.subscription_details.plan_name === plan.description
    },

    getButtonText(plan) {
      if (this.isCurrentPlan(plan)) {
        return 'Продлить подписку'
      }
      return plan.name === 'Advanced' ? 'Выбрать рекомендуемый' : this.$t('message.subscribeNow')
    },

    getButtonClasses(plan) {
      if (this.isCurrentPlan(plan)) {
        return 'bg-green-100 text-green-700 hover:bg-green-200'
      }
      return plan.name === 'Advanced'
        ? 'bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-500 hover:to-indigo-500 shadow-lg hover:shadow-xl'
        : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
    },

    showTrialButton(plan) {
      return !this.userStore.hasActiveSubscription &&
             (!this.userInfo.subscription_details ||
              this.userInfo.subscription_details.status !== 'trial')
    },

    getPlanFeatures(planName) {
      const features = {
        Basic: [
          'Автоматический расчет паттернов на 9',
          'Базовые методы создания мандал Сети Света',
          'Шаблоны: цветная, цифровая и точечные мандалы'
        ],
        Advanced: [
          'Все что включено в базовый тариф',
          'Способы расчета на 12, 13',
          'Продвинутые методы создания мандал Сети Света'
        ],
        Premium: [
          'Все что включено в продвинутый тариф',
          'Консультация по формулировке намерений',
          'Приоритетная поддержка'
        ]
      }
      return features[planName] || []
    },

    async fetchUserInfo() {
      try {
        const response = await axiosInstance.get('/api/auth/userinfo')
        this.userInfo = response.data
      } catch (error) {
        console.error('Error fetching user info:', error)
      }
    },

    async fetchPlans() {
      try {
        this.isLoading = true
        const response = await axiosInstance.get('/draw/api/v1/free/plans/')
        this.plans = response.data
      } catch (error) {
        console.error('Error fetching plans:', error)
      } finally {
        this.isLoading = false
      }
    },

    async selectPlan(plan) {
      this.isLoading = true
      try {
        // Implement your payment logic here
        console.log('Selected plan:', plan.id)
        const response = await axiosInstance.post('/draw/api/v1/free/subscription/purchase/', {
          plan_id: plan.id
        });
        if (response.data.payment_link) {
          window.location.href = response.data.payment_link;
        }
      } catch (error) {
        console.error('Payment error:', error)
      } finally {
        this.isLoading = false
      }
    },

    async startTrial(plan) {
      this.isLoading = true
      try {
        // Implement your trial activation logic here
        console.log('Starting trial for plan:', plan)
      } catch (error) {
        console.error('Trial activation error:', error)
      } finally {
        this.isLoading = false
      }
    }
  },

  mounted() {
    this.fetchPlans()
    this.fetchUserInfo()
  }
})
</script>

<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-50 via-indigo-50 to-purple-50 py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Header -->
      <div class="text-center mb-16">
        <h1 class="text-4xl font-bold text-gray-900 mb-4">
          {{ $t('message.unlockAllFeatures') }} ✨
        </h1>
        <p class="text-lg text-gray-600 max-w-2xl mx-auto">
          {{ userInfo.subscription_details ?
            `${$t('message.daysRemaining')}: ${userInfo.subscription_details.days_remaining} ${$t('message.days')}` :
            $t('message.limitedFeatures')
          }}
        </p>
      </div>

      <!-- Plans Grid -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
        <div v-for="plan in plans" :key="plan.id"
             class="relative group">
          <!-- Gradient border effect -->
          <div class="absolute -inset-[2px] bg-gradient-to-r from-purple-600 via-violet-600 to-indigo-600 rounded-2xl"
               :class="[
                 isCurrentPlan(plan) ? 'opacity-100' : 'opacity-20 group-hover:opacity-100',
                 'blur transition-all duration-300'
               ]">
          </div>

          <div class="relative h-full bg-white rounded-xl p-8 shadow-lg flex flex-col">
            <!-- Current Plan Badge -->
            <div v-if="isCurrentPlan(plan)"
                 class="absolute top-0 right-0 transform translate-x-2 -translate-y-2">
              <span class="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gradient-to-r from-green-500 to-emerald-600 text-white shadow-lg">
                Текущий тариф 👑
              </span>
            </div>

            <!-- Popular badge -->
            <div v-else-if="plan.name === 'Advanced'"
                 class="absolute top-0 right-0 transform translate-x-2 -translate-y-2">
              <span class="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg">
                Популярный 🌟
              </span>
            </div>

            <!-- Plan Header -->
            <div class="mb-8">
              <h3 class="text-2xl font-bold text-gray-900 mb-2">
                {{ plan.description }}
              </h3>
              <div class="flex items-baseline">
                <span class="text-4xl font-bold text-gray-900">
                  {{ formatPrice(plan.price) }}₽
                </span>
                <span class="ml-2 text-gray-500">/мес</span>
              </div>
            </div>

            <!-- Features List -->
            <ul class="space-y-4 mb-8 flex-grow">
              <li v-for="(feature, index) in getPlanFeatures(plan.name)"
                  :key="index"
                  class="flex items-start">
                <span class="flex-shrink-0 h-6 w-6 text-purple-600">
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                  </svg>
                </span>
                <span class="ml-3 text-gray-600">{{ feature }}</span>
              </li>
            </ul>

            <!-- Action Buttons -->
            <div class="space-y-4 mt-auto">
              <button
                @click="selectPlan(plan)"
                class="w-full rounded-lg py-3 px-4 text-center font-semibold transition-all duration-300"
                :class="getButtonClasses(plan)"
              >
                {{ getButtonText(plan) }}
              </button>

              <button
                v-if="showTrialButton(plan)"
                @click="startTrial(plan)"
                class="w-full rounded-lg py-3 px-4 text-center border-2 border-purple-200 text-purple-600 hover:bg-purple-50 transition-colors duration-300 font-medium"
              >
                {{ $t('message.trialRequest') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Trust Indicators -->
      <div class="text-center space-y-4 mt-16">
        <div class="flex flex-wrap justify-center gap-8">
          <div class="flex items-center">
            <span class="text-2xl mr-2">🔒</span>
            <span class="text-gray-600">Безопасная оплата</span>
          </div>
          <div class="flex items-center">
            <span class="text-2xl mr-2">⚡️</span>
            <span class="text-gray-600">Мгновенный доступ</span>
          </div>
          <div class="flex items-center">
            <span class="text-2xl mr-2">💫</span>
            <span class="text-gray-600">Начните меняться прямо сейчас</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading State -->
    <div v-if="isLoading"
         class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white p-6 rounded-lg shadow-xl">
        <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-600 mx-auto"></div>
        <p class="mt-4 text-gray-600">Обработка...</p>
      </div>
    </div>
  </div>
</template>
