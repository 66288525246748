<template>
    <div class="min-h-[60vh] bg-gradient-to-br from-purple-50 via-indigo-50 to-purple-50 py-12">
      <div class="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <div class="bg-white rounded-xl p-8 shadow-lg">
          <div class="mb-6">
            <div class="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
              <svg class="h-10 w-10 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
              </svg>
            </div>
          </div>

          <h2 class="text-3xl font-bold text-gray-900 mb-4">
            {{ $t('message.paymentSuccessTitle') }}
          </h2>

          <p class="text-lg text-gray-600 mb-8">
            {{ $t('message.paymentSuccessMessage') }}
          </p>

          <div class="space-y-4">
            <router-link
              to="/app"
              class="inline-block w-full sm:w-auto px-8 py-3 text-center font-semibold rounded-lg bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-500 hover:to-indigo-500 shadow-lg hover:shadow-xl transition-all duration-300"
            >
              {{ $t('message.goToApp') }}
            </router-link>

            <div class="flex items-center justify-center mt-6">
              <span class="text-2xl mr-2">✨</span>
              <span class="text-gray-600">{{ $t('message.enjoyYourSubscription') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { defineComponent } from 'vue'
  import { useRouter } from 'vue-router'
  import { useAuthStore } from '@/stores/user'
  import axiosInstance from '@/axios/axios'

  export default defineComponent({
    name: 'PaymentSuccess',

    async setup() {
      const router = useRouter()
      const userStore = useAuthStore()

      // Refresh subscription status
      try {
        const response = await axiosInstance.get('/api/auth/subscription/status/')
        // userStore.setSubscriptionStatus(response.data.is_active, response.data)
      } catch (error) {
        console.error('Error updating subscription status:', error)
      }
    }
  })
  </script>
