<template>
  <div class="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <!-- Header Section -->
    <div class="sm:mx-auto sm:w-full sm:max-w-2xl">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {{ i18n.t('message.registerCaption') }}
      </h2>

      <!-- Process Steps -->
      <div class="mt-8 max-w-2xl mx-auto px-4">
        <div class="flex justify-between mb-8">
          <div v-for="(step, index) in 3" :key="index" class="flex flex-col items-center w-1/3">
            <div :class="['h-8 w-8 rounded-full flex items-center justify-center text-sm font-medium',
              currentStep >= step ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-600']">
              {{ step }}
            </div>
            <div class="mt-2 text-xs text-center text-gray-500">
              {{ i18n.t(`message.registrationStep${step}`) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <!-- Subscription Plans -->
        <div v-if="currentStep === 1" class="space-y-6">
          <div class="text-center mb-6">
            <h3 class="text-lg font-medium text-gray-900">{{ i18n.t('message.choosePlanTitle') }}</h3>
            <p class="mt-1 text-sm text-gray-500">{{ i18n.t('message.choosePlanDescription') }}</p>
          </div>

          <div class="space-y-4">
            <div v-for="planOption in plans" :key="planOption.id"
                 @click="selectPlan(planOption.id)"
                 :class="['p-4 border rounded-lg cursor-pointer transition-colors',
                   plan === planOption.id ? 'border-indigo-500 bg-indigo-50' : 'border-gray-200 hover:border-indigo-200']">
              <div class="flex justify-between items-center">
                <div>
                  <h4 class="font-medium text-gray-900">{{ planOption.name }}</h4>
                  <p class="text-sm text-gray-500">{{ planOption.description }}</p>
                </div>
                <div class="text-xl font-bold text-indigo-600">₽{{ planOption.price }}</div>
              </div>
            </div>
          </div>

          <!-- Trial Option -->
          <div class="mt-6 bg-blue-50 p-4 rounded-md">
            <div class="flex items-center">
              <input
                id="trialRequested"
                v-model="trialRequested"
                type="checkbox"
                class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label for="trialRequested" class="ml-2 text-sm text-blue-800">
                {{ i18n.t('message.startWithTrial') }}
              </label>
            </div>
            <p class="mt-2 text-sm text-blue-600">{{ i18n.t('message.trialDescription') }}</p>
          </div>
          <button
            @click="nextStep"
            :disabled="!plan"
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {{ i18n.t('message.continue') }}
          </button>
        </div>

        <!-- Registration Form -->
        <form v-if="currentStep === 2" class="space-y-6" @submit.prevent="nextStep">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              {{ i18n.t('message.emailCaption') }}
            </label>
            <div class="mt-1">
              <input
                id="email"
                v-model="email"
                type="email"
                required
                :class="['appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
                  emailError ? 'border-red-500' : 'border-gray-300']"
              />
              <p v-if="emailError" class="mt-1 text-sm text-red-600">{{ emailError }}</p>
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              {{ i18n.t('message.passwordCaption') }}
            </label>
            <div class="mt-1">
              <input
                id="password"
                v-model="password"
                type="password"
                required
                :class="['appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
                  passwordError ? 'border-red-500' : 'border-gray-300']"
              />
              <p v-if="passwordError" class="mt-1 text-sm text-red-600">{{ passwordError }}</p>
            </div>
          </div>

          <div id="recaptcha"></div>

          <div class="flex space-x-3">
            <button
              type="button"
              @click="currentStep--"
              class="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ i18n.t('message.back') }}
            </button>
            <button
              type="submit"
              :disabled="!isFormValid"
              class="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {{ i18n.t('message.continue') }}
            </button>
          </div>
        </form>

        <!-- Confirmation Step -->
        <div v-if="currentStep === 3" class="space-y-6">
          <div class="text-center mb-6">
            <h3 class="text-xl font-medium text-gray-900">
              {{ i18n.t('message.confirmDetails') }}
            </h3>
          </div>

          <div class="bg-gray-50 rounded-lg p-6">
            <dl class="space-y-4">
              <!-- Plan Details -->
              <div class="flex justify-between items-center">
                <dt class="text-sm font-medium text-gray-500">
                  {{ i18n.t('message.selectedPlan') }}
                </dt>
                <dd class="text-sm font-semibold text-gray-900">
                  {{ selectedPlanName }}
                </dd>
              </div>

              <!-- Email -->
              <div class="flex justify-between items-center">
                <dt class="text-sm font-medium text-gray-500">
                  {{ i18n.t('message.emailCaption') }}
                </dt>
                <dd class="text-sm font-semibold text-gray-900">
                  {{ email }}
                </dd>
              </div>

              <!-- Trial Status -->
              <div class="flex justify-between items-center">
                <dt class="text-sm font-medium text-gray-500">
                  {{ i18n.t('message.trialStatus') }}
                </dt>
                <dd class="text-sm font-semibold text-gray-900">

                    {{ trialRequested ? i18n.t('message.trialEnabled') : i18n.t('message.trialDisabled') }}
                </dd>
              </div>
            </dl>
          </div>
          <div class="flex space-x-4 mt-8">
            <button
              type="button"
              @click="prevStep"
              class="flex-1 px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ i18n.t('message.back') }}
            </button>

            <button
              @click="handleSubmit"
              :disabled="isLoading"
              class="flex-1 px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              <template v-if="isLoading">
                <svg class="animate-spin -ml-1 mr-2 h-4 w-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {{ i18n.t('message.processing') }}
              </template>
              <template v-else>
                {{ i18n.t('message.completeRegistration') }}
              </template>
            </button>
          </div>
          <div class="recaptcha-container">
            <div ref="recaptchaRef" class="g-recaptcha"></div>
          </div>
        </div>

        <!-- Error Messages -->
        <div v-if="errors.length" class="mt-4 bg-red-50 border border-red-200 rounded-md p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">{{ i18n.t('message.errorTitle') }}</h3>
              <div class="mt-2 text-sm text-red-700">
                <ul class="list-disc pl-5 space-y-1">
                  <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Sign In Link -->
        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                {{ i18n.t('message.alreadyregisteredCaption') }}
              </span>
            </div>
          </div>
          <div class="mt-6 text-center">
            <router-link
              to="/signin"
              class="font-medium text-indigo-600 hover:text-indigo-500"
            >
              {{ i18n.t('message.signinCaption') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import axiosInstance from '@/axios/axios';
import { useAuthStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
const i18n = useI18n();
// Constants
const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY
// Important: Define the ref for recaptcha element
const recaptchaRef = ref(null);
const recaptchaWidget = ref(null);
// State Management
const currentStep = ref(1);
const email = ref('');
const password = ref('');
const plan = ref('');
const trialRequested = ref(false);
const plans = ref([]);
const isLoading = ref(false);
const errors = ref([]);
const emailError = ref('');
const passwordError = ref('');

const router = useRouter();
const authStore = useAuthStore();

// Computed Properties
const isFormValid = computed(() => {
  return email.value &&
    password.value &&
    plan.value &&
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value);
});

const selectedPlanName = computed(() => {
  const selectedPlan = plans.value.find(p => p.id === plan.value);
  return selectedPlan?.name || '';
});

const currentStepValid = computed(() => {
  switch (currentStep.value) {
    case 1:
      return !!plan.value;
    case 2:
      return !!email.value && !!password.value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value);
    case 3:
      return true;
    default:
      return false;
  }
});

// Methods
const loadReCaptcha = () => {
  // Clear any existing recaptcha instances
  if (recaptchaWidget.value !== null && window.grecaptcha) {
    window.grecaptcha.reset(recaptchaWidget.value);
  }

  const script = document.createElement('script');
  script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
  script.async = true;
  script.defer = true;

  script.onload = () => {
    const initializeRecaptcha = () => {
      if (window.grecaptcha && recaptchaRef.value) {
        try {
          // Clear existing content
          while (recaptchaRef.value.firstChild) {
            recaptchaRef.value.removeChild(recaptchaRef.value.firstChild);
          }

          recaptchaWidget.value = window.grecaptcha.render(recaptchaRef.value, {
            'sitekey': RECAPTCHA_SITE_KEY,
            // 'callback': (response) => {
            //   console.log('reCAPTCHA callback received');
            //   verifyReCaptcha(response);
            // },
            'expired-callback': () => {
              console.log('reCAPTCHA expired');
              errors.value.push(i18n.t('message.recaptchaExpired'));
              window.grecaptcha.reset(recaptchaWidget.value);
            },
            'error-callback': () => {
              console.log('reCAPTCHA error');
              errors.value.push(i18n.t('message.recaptchaError'));
              window.grecaptcha.reset(recaptchaWidget.value);
            },
          });
        } catch (error) {
          console.error('Error rendering reCAPTCHA:', error);
          errors.value.push(i18n.t('message.recaptchaLoadError'));
        }
      } else {
        setTimeout(initializeRecaptcha, 100);
      }
    };

    window.onloadCallback = initializeRecaptcha;

    if (window.grecaptcha?.render) {
      initializeRecaptcha();
    }
  };

  script.onerror = () => {
    console.error('Error loading reCAPTCHA script');
    errors.value.push(i18n.t('message.recaptchaLoadError'));
  };

  document.head.appendChild(script);
};
// Helper function to check if reCAPTCHA is valid
const isRecaptchaValid = () => {
  try {
    return !!window.grecaptcha?.getResponse(recaptchaWidget.value);
  } catch (error) {
    console.error('Error checking reCAPTCHA response:', error);
    return false;
  }
};
// Watch for step changes
watch(currentStep, (newStep) => {
  if (newStep === 2) {
    nextTick(() => {
      if (!window.grecaptcha) {
        loadReCaptcha();
      } else {
        if (recaptchaWidget.value !== null) {
          window.grecaptcha.reset(recaptchaWidget.value);
        } else {
          loadReCaptcha();
        }
      }
    });
  }
});
// Cleanup function
onUnmounted(() => {
  if (window.grecaptcha && recaptchaWidget.value !== null) {
    try {
      window.grecaptcha.reset(recaptchaWidget.value);
    } catch (error) {
      console.error('Error cleaning up reCAPTCHA:', error);
    }
  }
});
const verifyReCaptcha = async (response) => {
  try {
    const { data } = await axiosInstance.post('/api/auth/verify-recaptcha/', {
      response
    });
    return data.success;
  } catch (error) {
    console.error('ReCAPTCHA verification error:', error);
    errors.value.push(i18n.t('message.recaptchaVerificationError'));
    return false;
  }
};



    // Methods
    const selectPlan = (planId) => {
      plan.value = planId;
    };

    const loadPlans = async () => {
      try {
        isLoading.value = true;
        const { data } = await axiosInstance.get("/draw/api/v1/free/plans");
        plans.value = data;
      } catch (error) {
        console.error('Error loading plans:', error);
        errors.value.push('Failed to load subscription plans');
      } finally {
        isLoading.value = false;
      }
    };

const validateStep = () => {
  errors.value = [];
  emailError.value = '';
  passwordError.value = '';

  switch (currentStep.value) {
    case 1:
      if (!plan.value) {
        errors.value.push(i18n.t('message.selectPlanRequired'));
        return false;
      }
      break;
    case 2:
      if (!email.value) {
        emailError.value = i18n.t('message.emailRequired');
        return false;
      }
      if (!password.value) {
        passwordError.value = i18n.t('message.passwordRequired');
        return false;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)) {
        emailError.value = i18n.t('message.emailInvalid');
        return false;
      }
      break;
  }
  return true;
};

const nextStep = () => {
  if (validateStep()) {
    currentStep.value++;
  }
};

const prevStep = () => {
  currentStep.value--;
};

const handleSubmit = async () => {
  if (!isFormValid.value) {
    errors.value.push(i18n.t('message.formValidationError'));
    return;
  }

  isLoading.value = true;
  errors.value = [];

  try {
    // Verify ReCAPTCHA
    const recaptchaResponse = window.grecaptcha.getResponse(recaptchaWidget.value);
    if (!recaptchaResponse) {
      errors.value.push(i18n.t('message.recaptchaRequired'));
      return;
    }

    const recaptchaValid = await verifyReCaptcha(recaptchaResponse);
    if (!recaptchaValid) {
      window.grecaptcha.reset(recaptchaWidget.value);
      errors.value.push(i18n.t('message.recaptchaVerificationError'));
      return;
    }

    // Register user
    const registerData = {
      username: email.value,
      email: email.value,
      password: password.value,
      subscription_plan: plan.value,
      free_trial: trialRequested.value
    };

    const { data: registerResponse } = await axiosInstance.post('/api/auth/register/', registerData);

    if (registerResponse) {
      // Login after successful registration
      const loginSuccess = await authStore.login({
        username: email.value,
        password: password.value
      });

      if (loginSuccess) {
        router.push('/app');
      } else {
        router.push('/signin');
      }
    }
  } catch (error) {
    console.error('Registration error:', error);
    const errorMessage = error.response?.data?.message || i18n.t('message.registrationError');
    errors.value.push(errorMessage);

    if (error.response?.status === 400) {
      const errorData = error.response.data;
      if (errorData.email) emailError.value = errorData.email[0];
      if (errorData.password) passwordError.value = errorData.password[0];
    }

    window.grecaptcha?.reset(recaptchaWidget.value);
  } finally {
    isLoading.value = false;
  }
};

// Lifecycle Hooks
onMounted(async () => {
  await loadPlans();
  await loadReCaptcha();
});
</script>
