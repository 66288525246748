<template>
  <div class="container mx-auto px-4">
    <div class="text-center mb-6">
      <h2>Демонстрационный расчет и построение фигуры</h2>
      <h7 class="text">Это ограниченный вариант приложения для демонтрации его возможностей и способа построения фигур</h7>
      <p></p>
      <h8 class="register">Для полноценного использования функционала <a href="https://t.me/OlgaChamina" class="href">зарегистрируйтесь</a> и приобретите подписку</h8>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-8 gap-4">
      <div class="md:col-span-6">
        <img
          v-if="b64image !== ''"
          :src="'data:image/jpeg;base64,' + b64image"
          class="w-full h-auto"
          alt="Generated Mandala"
        />
      </div>
      <div class="md:col-span-2">
        <div class="mb-4">
          <label for="whishphrase" class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('message.wishPhrase') }}
          </label>
          <textarea
            v-model="payload"
            id="whishphrase"
            rows="3"
            class="w-full px-3 py-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            :placeholder="$t('message.wishPhrase')"
            :disabled="true"
          ></textarea>
        </div>

        <div class="mb-4">
          <label for="chooseShape" class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('message.chooseShape') }}
          </label>
          <select
            class="w-full px-3 py-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            :aria-label="$t('message.shapes')"
            :disabled="true"
          >
            <option>{{ $t('message.testcalc_shape') }}</option>
          </select>
        </div>

        <div class="mb-4">
          <label for="v-model-select-method-one" class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('message.methodOne') }}
          </label>
          <select
            v-model="firstMethodSelected"
            class="w-full px-3 py-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            :disabled="true"
          >
            <option>6</option>
          </select>
        </div>

        <div class="mb-4">
          <label for="v-model-select-method-two" class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('message.methodTwo') }}
          </label>
          <select
            v-model="additionalMethodSelected"
            class="w-full px-3 py-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            :disabled="true"
          >
            <option>6</option>
          </select>
        </div>

        <div class="mb-4">
          <label class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('message.patternLength') }}
          </label>
          <div class="flex flex-wrap gap-2">
            <template v-for="patternValue in patternsList" :key="patternValue">
              <div>
                <input
                  type="radio"
                  :id="'btr-patternlen' + (patternValue + 1)"
                  name="brt-patternlen"
                  :value="patternValue + 1"
                  v-model="patternLenSelected"
                  @change="patternClick($event)"
                  class="hidden peer"
                />
                <label
                  :for="'btr-patternlen' + (patternValue + 1)"
                  class="inline-flex items-center justify-center px-3 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 peer-checked:bg-blue-600 peer-checked:text-white"
                >
                  {{ patternValue + 1 }}
                </label>
              </div>
            </template>
          </div>
        </div>

        <div v-if="patternSymbols !== ''" class="mb-4">
          <span class="text-sm text-gray-600">
            {{ $t('message.pattern') }}
            (<a
              href="#"
              @click.prevent="open = true"
              class="text-blue-600 hover:underline"
            >{{ $t('message.seeAll') }}</a>):
          </span>
          <span class="text-sm font-medium">{{ patternSymbols }}</span>
        </div>

        <div class="mb-4">
          <label class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('message.paletteSelect') }}
          </label>
          <div class="flex gap-2">
            <template v-for="value in [9, 12, 13]" :key="value">
              <div>
                <input
                  type="radio"
                  :id="'btr-pal' + value"
                  name="btr-palette"
                  :value="value"
                  v-model="paletteSelected"
                  @change="patternClick($event)"
                  class="hidden peer"
                />
                <label
                  :for="'btr-pal' + value"
                  class="inline-flex items-center justify-center px-3 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 peer-checked:bg-blue-600 peer-checked:text-white"
                >
                  {{ value }}
                </label>
              </div>
            </template>
          </div>
        </div>

        <div class="mb-4">
          <label class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('message.paletteLegend') }}
          </label>
          <table class="w-full text-center">
            <tbody>
              <tr>
                <td
                  v-for="color in paletteRendered[1]"
                  :key="color.id"
                  :style="{ backgroundColor: color.hex }"
                  class="w-8 h-8 text-xs"
                >
                  {{ color.id }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="color in paletteRendered[2]"
                  :key="color.id"
                  :style="{ backgroundColor: color.hex }"
                  class="w-8 h-8 text-xs"
                >
                  {{ color.id }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mb-4">
          <label class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('message.viewsCaption') }}
          </label>
          <div class="flex rounded-md shadow-sm" role="group">
            <button
              type="button"
              :class="[
                'px-4 py-2 text-sm font-medium rounded-l-md focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-600',
                mandalaForm === 'colorized'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-50'
              ]"
              @click="mandalaForm = 'colorized'"
            >
              {{ $t('message.colorizedCaption') }}
            </button>
            <button
              type="button"
              :class="[
                'px-4 py-2 text-sm font-medium focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-600',
                mandalaForm === 'digits'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-50'
              ]"
              @click="mandalaForm = 'digits'"
            >
              {{ $t('message.digitsCaption') }}
            </button>
            <button
              type="button"
              :class="[
                'px-4 py-2 text-sm font-medium rounded-r-md focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-600',
                mandalaForm === 'dots'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-50'
              ]"
              @click="mandalaForm = 'dots'"
            >
              {{ $t('message.dotsCaption') }}
            </button>
          </div>
        </div>

        <div
          class="mb-3"
          v-if="b64image != '' && mandalaForm == 'dots'"
        >
          <div class="mb-4">
            <label for="mainMatrix" class="block mb-2 text-sm font-medium text-gray-700">
              {{ $t("message.yourMatrix") }}
            </label>
            <input
              type="text"
              id="mainMatrix"
              v-model="pattern"
              aria-describedby="wishhelp"
              class="w-full px-3 py-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        <div class="flex space-x-2">
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            @click="processrequest"
          >
            {{ $t('message.go') }}
          </button>
          <button
            v-if="b64image"
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            @click="print_open = true"
          >
            {{ $t('message.print') }}
          </button>
        </div>

        <div v-if="result.length > 0" class="mt-4">
          <label class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('message.calcresult') }}
          </label>
          <table class="w-full text-center">
            <tbody>
              <tr>
                <template v-for="(item, index) in payload" :key="index">
                  <td v-if="/\w/.test(item)" class="px-2 py-1 text-sm">
                    {{ item }}
                  </td>
                </template>
              </tr>
              <tr v-for="(item, index) in result" :key="index">
                <td v-for="(elem, indexj) in item" :key="indexj" class="px-2 py-1 text-sm">
                  {{ elem }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Modals -->
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <!-- Modal implementation -->
    </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="print_open">
    <Dialog as="div" class="relative z-10" @close="print_open = false">
      <!-- Print modal implementation -->
    </Dialog>
  </TransitionRoot>
</template>

  <script>
  import axios from "axios";
  const API_URL =
    typeof import.meta.env.VITE_ROOTAPI === "undefined"
      ? "http://service:8000"
      : import.meta.env.VITE_ROOTAPI;

  import { useAuthStore } from "@/stores/user";
  import { inject } from 'vue';
  export default {
    name: "SignInView",
    setup() {
      const userStore = useAuthStore();
      const payloadRegex = inject('payloadRegex');
      return { payloadRegex, userStore };
    },
    data() {
      return {
        signInDetails: {
          username: "",
          password: "",
        },
        payload: "мои желания исполняются наилучшим для меня образом во благо мне и всех, кого это касается",
        mainMatrix: "",
        result: "",
        patternLenSelected: 7,
        paletteSelected: 9,
        patternSymbols: "",
        palette: "",
        commondata: { menu: { shapes: {} } },
        patternsList: 0,
        pattern: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20",
        mandalaForm: "dots",
        b64image: "",
        printDefaultMandala: "true",
        printOnlyDigits: "false",
        printOnlyDots: "false",
        shapeSelected: "",
        firstMethodSelected: 6,
        additionalMethodSelected: 6,
        main_matrix: { 1: [1] },
      };
    },
    computed: {
      mainMatrixRendered: function () {
        let result = [];
        if (Object.keys(this.main_matrix).length > 1) {
          if (this.main_matrix[this.paletteSelected].length > 0) {
            for (
              let i = 0;
              i < this.main_matrix[this.paletteSelected].length;
              i++
            ) {
              let row = [];
              if (Array.isArray(this.main_matrix[this.paletteSelected][i])) {
                for (
                  let j = 0;
                  j < this.main_matrix[this.paletteSelected].length;
                  j++
                ) {
                  if (j < this.main_matrix[this.paletteSelected][i].length) {
                    row[j] = this.main_matrix[this.paletteSelected][i][j];
                  } else {
                    row[j] = "";
                  }
                }
                result[i] = row;
              }
            }
            return result;
          } else {
            return result;
          }
        } else {
          return result;
        }
      },
      btnIsDisable() {
        return this.payload.length < 1 && this.palette == undefined;
      },
      paletteRendered: function () {
        var result = {};
        if (this.patternSymbols) {
          var pattern_first_symbol =
            this.patternSymbols[0] < 2 ? 1 : this.patternSymbols[0];
          var temp_palette =
            this.commondata["palettes"][this.paletteSelected][
              pattern_first_symbol
            ];
          result[1] = {};
          result[2] = {};
          for (let i = 1; i <= 7; i++) {
            result[1][i] = {};
            result[1][i]["id"] = i;
            result[1][i]["hex"] = temp_palette[i];
          }
          for (let i = 8; i <= this.paletteSelected; i++) {
            result[2][i] = {};
            result[2][i]["id"] = i;
            result[2][i]["hex"] = temp_palette[i];
          }
          return result;
        }
        return result;
      }
    },
    created() {
      this.shapeSelected = "demoshape"
      this.draw_url = `${API_URL}/draw/api/v1/free/${this.shapeSelected}?`;
      console.log(this.payload)
      axios
        .get(`${API_URL}/draw/api/v1/free/shapesdata/`, {
          params: {
            shape: this.shapeSelected,
            q: this.payload,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          this.commondata = Object.assign({}, this.commondata, data);
          this.main_matrix = data.main_matrix;
          var min_val = 0;
          min_val = Math.min(
            this.payload.length,
            this.commondata["pattern_max_len"]
          )
            ? Math.min(this.payload.length, this.commondata["pattern_max_len"])
            : 0;
          this.patternsList = Array.from(Array(min_val).keys());
          this.patternClick();
        });
    },
    methods: {
      selectShapeId(e) {
        this.shapeSelectedId = e.id;
      },
      buildQuerydata: function () {
        const raw = this.$data;
        const allowed = [
          "payload",
          "shapeSelected",
          "shapeSelectedId",
          "pattern",
          "paletteSelected",
          "firstMethodSelected",
          "additionalMethodSelected",
          "patternLenSelected",
          "printDefaultMandala",
          "printOnlyDigits",
          "printOnlyDots",
        ];
        const filtered = Object.keys(raw)
          .filter((key) => allowed.includes(key))
          .reduce((obj, key) => {
            obj[key] = raw[key];
            return obj;
          }, {});
        return filtered;
      },
      gotoPrint() {
        let route = this.$router.resolve({
          path: "/print",
          query: this.buildQuerydata(),
        });
        window.open(route.href);
      },
      patternClick() {
        console.log(this.commondata);
        this.patternSymbols =
          this.commondata["main_matrix"][this.paletteSelected][
            this.commondata["main_matrix"][this.paletteSelected].length -
              this.patternLenSelected
          ];
        // this.gotoStep(2);
        this.pattern = this.patternSymbols
      },
      processrequest() {
        console.log("processing request");
        switch (this.mandalaForm) {
          case "colorized":
            console.log("colorizing mandala");
            this.colorizeShape();
            break;
          case "digits":
            this.unColorizeShape();
            break;
          case "dots":
            this.filterShape();
            break;
        }
        // this.gotoStep(3);
      },
      sendDrawRequest(data) {
        let url = this.draw_url + encodeQueryData(data);
        return axios.get(url, this.sec_headers).then((response) => {
          (this.b64image = response.data.pic || ""),
            (this.pattern = response.data.pattern);
        });
      },
      colorizeShape() {
        const data = {
          q: this.payload,
          patternLength: this.patternLenSelected,
          palette_id: this.paletteSelected,
          first_method: this.firstMethodSelected,
          additional_method: this.additionalMethodSelected,
          colorize: 1,
        };
        this.sendDrawRequest(data);
      },
      unColorizeShape() {
        const data = {
          q: this.payload,
          patternLength: this.patternLenSelected,
          palette_id: this.paletteSelected,
          first_method: this.firstMethodSelected,
          additional_method: this.additionalMethodSelected,
          colorize: 0,
          filter: "*",
        };
        this.sendDrawRequest(data);
      },
      filterShape() {
        const data = {
          q: this.payload,
          patternLength: this.patternLenSelected,
          palette_id: this.paletteSelected,
          first_method: this.firstMethodSelected,
          additional_method: this.additionalMethodSelected,
          colorize: 0,
          num_to_dot: 1,
          filter: this.pattern,
        };
        this.sendDrawRequest(data);
      },
    },
  };
  function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
  }
  </script>
