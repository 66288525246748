<template>
  <div class="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {{ $t('message.signinCaption') }}
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="handleSubmit">
          <div>
            <label for="username" class="block text-sm font-medium text-gray-700">
              {{ $t("message.usernameCaption") }}
            </label>
            <div class="mt-1">
              <input
                id="username"
                v-model="signInDetails.username"
                name="username"
                type="text"
                autocomplete="username"
                required
                :disabled="isLoading"
                :class="['appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
                  usernameError ? 'border-red-500' : 'border-gray-300']"
              />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              {{ $t("message.passwordCaption") }}
            </label>
            <div class="mt-1">
              <input
                id="password"
                v-model="signInDetails.password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                :disabled="isLoading"
                :class="['appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
                  passwordError ? 'border-red-500' : 'border-gray-300']"
              />
            </div>
          </div>

          <!-- Google reCAPTCHA -->
          <div ref="recaptcha" class="flex justify-center"></div>

          <div>
            <button
              type="submit"
              :disabled="!isFormValid || isLoading"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <template v-if="isLoading">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {{ $t("message.signingIn") }}
              </template>
              <template v-else>
                {{ $t("message.signinCaption") }}
              </template>
            </button>
          </div>
        </form>

        <!-- Error Messages -->
        <div v-if="errors.length > 0" class="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong class="font-bold">{{ $t('message.errorTitle') }}</strong>
          <ul class="mt-1">
            <li v-for="(error, index) in errors" :key="index" class="block sm:inline">{{ error }}</li>
          </ul>
        </div>

        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                {{ $t("message.notRegistredCaption") }}
              </span>
            </div>
          </div>
          <div class="mt-6 text-center">
            <router-link
              :to="{name: 'register'}"
              class="font-medium text-indigo-600 hover:text-indigo-500"
            >
              {{ $t("message.registerCaption") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axios/axios';
import { useAuthStore } from '@/stores/user';
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const RECAPTCHA_SITE_KEY = '6Leb6koqAAAAALa5sZK5yQedWUnM3zRg36KH91vT';

export default {
  setup() {
    const recaptcha = ref(null);
    const recaptchaToken = ref('');
    const isLoading = ref(false);
    const authStore = useAuthStore();
    const router = useRouter();
    const route = useRoute();

    // Move these from data() into setup()
    const signInDetails = ref({
      username: "",
      password: "",
    });
    const errors = ref([]);
    const usernameError = ref(false);
    const passwordError = ref(false);

    // Computed property in setup
    const isFormValid = () => {
      return signInDetails.value.username &&
        signInDetails.value.password &&
        recaptchaToken.value;
    };

    return {
      recaptcha,
      recaptchaToken,
      isLoading,
      authStore,
      signInDetails,
      errors,
      usernameError,
      passwordError,
      isFormValid,
    };
  },

  methods: {
    loadRecaptcha() {
      if (window.grecaptcha) {
        this.renderRecaptcha();
        return;
      }

      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=explicit`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        this.renderRecaptcha();
      };

      document.head.appendChild(script);
    },

    renderRecaptcha() {
      window.grecaptcha?.ready(() => {
        try {
          window.grecaptcha.render(this.$refs.recaptcha, {
            'sitekey': RECAPTCHA_SITE_KEY,
            'callback': this.handleRecaptchaToken,
            'expired-callback': () => {
              this.recaptchaToken = '';
            },
            'error-callback': () => {
              this.errors.push(this.$t('message.recaptchaError'));
            }
          });
        } catch (error) {
          console.error('Error rendering reCAPTCHA:', error);
        }
      });
    },

    handleRecaptchaToken(token) {
      this.recaptchaToken = token;
    },

    resetErrors() {
      this.errors = [];
      this.usernameError = false;
      this.passwordError = false;
    },

    async verifyRecaptcha() {
      try {
        const response = await axiosInstance.post("/api/auth/verify-recaptcha/", {
          response: this.recaptchaToken
        });
        return response.data.success;
      } catch (error) {
        console.error("reCAPTCHA verification failed:", error);
        this.errors.push(this.$t("message.recaptchaFailed"));
        return false;
      }
    },


    async handleSubmit() {
      // Now this will work because signInDetails is properly defined
      if (!this.signInDetails.username || !this.signInDetails.password) {
        this.errors = ["Please fill in all fields"];
        return;
      }

      this.isLoading = true;
      this.resetErrors();

      try {
        // 1. Verify reCAPTCHA first
        const isRecaptchaValid = await this.verifyRecaptcha();
        if (!isRecaptchaValid) {
          window.grecaptcha?.reset();
          return;
        }

        // 2. Attempt login
        const success = await this.authStore.login({
          username: this.signInDetails.username,
          password: this.signInDetails.password,
        });

        if (success) {
          // Get redirect path if any
          const redirect = this.$route.query.redirect || '/app';
          this.$router.push(redirect);
        } else {
          this.errors = [this.authStore.getError || "Login failed"];
          this.usernameError = true;
          this.passwordError = true;
        }
      } catch (error) {
        this.errors = ["An unexpected error occurred"];
        console.error("Login error:", error);
      } finally {
        this.isLoading = false;
      }
    }
  },

  mounted() {
    this.loadRecaptcha();
  }
};
</script>
