import { createI18n } from 'vue-i18n'
import UserProfile from '../views/UserProfile.vue';

const messages = {
    en: {
      message: {
        palette: 'Palette',
        choose: 'Choose...',
        blogheader: "Blog and news",
        shapes: 'Shapes',
        wishPhrase: 'Wish phrase',
        wishPhraseDesc: 'Enter your wish phrase',
        chooseShape: 'Please choose the shape of Mandala',
        go: 'Process',
        close: 'Close',
        print: 'Print',
        printHint: 'Next you will see the print dialog window. Please choose installed printer to prinf or "Save to PDF" to save it to a file',
        patternLength: 'Pattern length',
        pattern: 'Pattern',
        patternDesc: 'Your pattern is',
        paletteSelect: 'Choose the palette',
        paletteLegend: 'Palette legend',
        seeAll: 'see all',
        matrixOfPatterns: 'Matrix of patterns',
        processRequest: 'Process request',
        yourMatrix: 'Your matrix is',
        filterDigits: 'Filter digits',
        showDigits: 'Show the digits',
        methodOne: 'Select method one',
        methodTwo: 'Select method two',
        services: 'Services',
        service1: 'Service-1',
        service2: 'Service-2 payed',
        service3: 'Service-3',
        service4: 'Service-4 payed',
        service5: 'Service-5 ',
        navbar_app: 'Draw now',
        navbar_blog: 'Blog',
        navbar_democalc: 'Demo calculation',
        navbar_testcalc: 'Test calculation',
        testcalc_shape: 'Octagon',
        viewsCaption: 'Choose manala form',
        printsCaption: 'Choose manala forms to print',
        colorizedCaption: 'Color',
        digitsCaption: 'Digits',
        dotsCaption: 'Dots',
        dotsByNumberCaption: 'Dotted by numbers',
        calcresult: 'Calculation results',
        usernameCaption: 'Username',
        emailCaption: 'Email',
        alreadyregisteredCaption: 'Already have an account? Try',
        passwordCaption: 'Password',
        signinCaption: 'Login',
        registerCaption: 'Sign Up',
        notRegistredCaption: 'Not a member yet?',
        sectorLength: 'Sector Length',
        loginError: 'Something went wrong, please refresh and try again.',
        errorTitle: 'Error during request: ',
        trialRequest: 'I need 3 days trial',
        planCaption: 'Select subscription plan',
        signinCaption: "Sign in to your account",
        usernameCaption: "Username",
        passwordCaption: "Password",
        signingIn: "Signing in...",
        errorTitle: "Error",
        notRegistredCaption: "Not registered yet?",
        registerCaption: "Create an account",
        fillAllFields: "Please fill all required fields",
        recaptchaFailed: "reCAPTCHA verification failed. Please try again",
        recaptchaError: "Error loading reCAPTCHA",
        invalidCredentials: "Invalid username or password",
        loginError: "An error occurred during sign in",
        networkError: "Network error. Please check your connection",
        tooManyAttempts: "Too many attempts. Please try again later",
        userProfileCaption: 'User profile',
        locationCaption: 'Location',
        bioCaption: 'Bio',
        joinedCaption: 'Joined',
        subscriptionStatus: 'Subscription status',
        subscriptionPlan: 'Plan',
        status: 'Статус',
        validUntil: 'Valid Until',
        daysRemaining: 'Days Remaining',
        unlockFeatures: "Unlock Premium Features",
        unlockAllFeatures: "Unlock All Premium Features Today",
        subscribeNow: "Subscribe Now",
        upgradeNow: "Upgrade Now",
        noActiveSubscription: "No Active Subscription",
        limitedFeatures: "You currently have access to limited features",
        notSpecified: "Not specified",
        noBio: "No bio provided",
        trialStatus: "Trial",
        activeStatus: "Active",
        pendingStatus: "Pending",
        profileLoadError: "Failed to load user profile",
        days: "days",
        month: "month",
        // Registration Steps
        registrationStep1: 'Choose Plan',
        registrationStep2: 'Account Details',
        registrationStep3: 'Confirmation',

        // Plan Selection
        choosePlanTitle: 'Select Your Subscription Plan',
        choosePlanDescription: 'Choose a plan that best fits your needs. All plans include full access to our features.',
        selectPlanRequired: 'Please select a subscription plan',
        selectedPlan: 'Selected Plan',
        planCaption: 'Subscription Plan',
        startWithTrial: 'Start with a free trial',
        trialDescription: 'Try all features free for 3 days. No credit card required.',
        trialRequest: 'Start with free trial period',
        trialEnabled: 'Trial Period Enabled',
        trialDisabled: 'No Trial Period',
        trialStatus: 'Trial Status',

        // Form Labels & Captions
        registerCaption: 'Create Account',
        emailCaption: 'Email Address',
        passwordCaption: 'Password',
        emailRequired: 'Email address is required',
        emailInvalid: 'Please enter a valid email address',
        passwordRequired: 'Password is required',

        // Navigation & Buttons
        continue: 'Continue',
        back: 'Back',
        processing: 'Processing...',
        completeRegistration: 'Complete Registration',

        // Confirmation
        confirmDetails: 'Confirm Your Details',
        email: 'Email',

        // Authentication
        alreadyregisteredCaption: 'Already have an account?',
        signinCaption: 'Sign In',

        // Errors
        errorTitle: 'Error occurred',
        formValidationError: 'Please check your input and try again',
        recaptchaRequired: 'Please complete the reCAPTCHA verification',
        recaptchaLoadError: 'Failed to load reCAPTCHA',
        recaptchaVerificationError: 'reCAPTCHA verification failed',
        plansLoadError: 'Failed to load subscription plans',
        registrationError: 'Registration failed. Please try again.',
        networkError: 'Network connection error. Please check your internet connection.',

        // Success Messages
        registrationSuccess: 'Registration successful! Redirecting to app...',

        // Subscription Info
        subscriptionRequired: 'Subscription Required',
        subscriptionRequiredDesc: 'A subscription is required to access all features of the application',

      }
    },
    ru: {
      message: {
        palette: 'Способ',
        choose: 'Выберите...',
        blogheader: "Блог и новости",
        shapes: 'Фигуры',
        wishPhrase: 'Намерение',
        wishPhraseDesc: 'Введите свое желание здесь',
        chooseShape: 'Выберите фигуру',
        go: 'Сформировать',
        close: 'Закрыть',
        print: 'Печать',
        printHint: 'Сейчас появится диалоговое окно печати. Выберите установленный принтер для печати или выберите "Сохранить в PDF", если хотите сохранить',
        patternLength: 'Длина паттерна',
        pattern: 'Паттерн',
        patternDesc: 'Ваш паттерн',
        paletteSelect: 'Выберите Способ',
        paletteLegend: 'Легенда палитры',
        seeAll: 'см. все',
        matrixOfPatterns: 'Матрица паттернов',
        processRequest: 'Обработать запрос',
        yourMatrix: 'Ваша матрица',
        filterDigits: 'Отфильтровать цифры',
        showDigits: 'Показать цифры',
        methodOne: 'Выберите первый метод',
        methodTwo: 'Выберите второй метод',
        services: 'Услуги',
        service1: 'Услуга-1',
        service2: 'Услуга-2 платная',
        service3: 'Услуга-3',
        service4: 'Услуга-4',
        service5: 'Услуга-5',
        navbar_app: 'К мандалам',
        navbar_blog: 'Блог',
        navbar_democalc: 'Демо расчет',
        navbar_testcalc: 'Пробный расчет',
        testcalc_shape: 'Восьмиугольник',
        calcresult: 'Результаты расчета',
        viewsCaption: 'Выберите форму мандалы',
        printsCaption: 'Выберите формы мандалы для печати',
        colorizedCaption: 'Цветная',
        digitsCaption: 'Цифры',
        dotsCaption: 'Точки',
        dotsByNumberCaption: 'Точки по номерам',
        loginCaption: 'Логин',
        passwordCaption: 'Пароль',
        signinCaption: 'Войти',
        emailCaption: 'Электронная почта',
        alreadyregisteredCaption: 'Уже зарегистрированы? Попробуйте',
        notRegistredCaption: 'Еще не зарегистрированы?',
        loginError: 'Ошибка при входе, проверьте логин и пароль.',
        sectorLength: 'Длина сектора',
        errorTitle: 'Возникла ошибка: ',
        trialRequest: 'Мне нужен 3х дневный пробный период',
        planCaption: 'Выберите тарифный план',
        planCaption: 'Выберите план подписки',
        signinCaption: 'Войти в аккаунт',
        usernameCaption: 'Имя пользователя',
        passwordCaption: 'Пароль',
        signingIn: 'Выполняется вход...',
        errorTitle: 'Ошибка',
        notRegistredCaption: 'Ещё нет аккаунта?',
        registerCaption: 'Создать аккаунт',
        fillAllFields: 'Пожалуйста, заполните все обязательные поля',
        recaptchaFailed: 'Проверка reCAPTCHA не удалась. Пожалуйста, попробуйте снова',
        recaptchaError: 'Ошибка загрузки reCAPTCHA',
        invalidCredentials: 'Неверное имя пользователя или пароль',
        loginError: 'Произошла ошибка при входе',
        networkError: 'Ошибка сети. Пожалуйста, проверьте подключение',
        tooManyAttempts: 'Слишком много попыток. Пожалуйста, попробуйте позже',
        userProfileCaption: 'Профиль пользователя',
        locationCaption: 'Местоположение',
        bioCaption: 'О себе',
        joinedCaption: 'Дата Регистрации',
        subscriptionStatus: 'Статус подписки',
        status: 'Статус',
        validUntil: 'Действует до',
        unlockFeatures: 'Разблокировать премиум функции',
        unlockAllFeatures: 'Получите доступ ко всем премиум функциям сегодня',
        subscribeNow: 'Подписаться',
        upgradeNow: 'Возобновить',
        noActiveSubscription: 'Нет активной подписки',
        limitedFeatures: 'У вас доступ к ограниченному функционалу',
        notSpecified: 'Не указано',
        noBio: 'Информация о себе не указана',
        trialStatus: 'Пробный период',
        activeStatus: 'Активна',
        pendingStatus: 'В обработке',
        profileLoadError: 'Ошибка загрузки профиля',
        days: 'дней',
        userProfileCaption: 'Профиль пользователя',
        bioCaption: 'О себе',
        subscriptionStatus: 'Статус подписки',
        subscriptionPlan: 'Текущий тариф',
        status: 'Статус',
        validUntil: 'Действует до',
        daysRemaining: 'Дней осталось',
        month: "в мес",
        // Registration Steps
        registrationStep1: 'Выбор плана',
        registrationStep2: 'Данные аккаунта',
        registrationStep3: 'Подтверждение',

        // Plan Selection
        choosePlanTitle: 'Выберите план подписки',
        choosePlanDescription: 'Выберите план, который подходит вашим потребностям. Все планы включают полный доступ к функциям.',
        selectPlanRequired: 'Пожалуйста, выберите план подписки',
        selectedPlan: 'Выбранный план',
        planCaption: 'План подписки',
        startWithTrial: 'Начать с бесплатного периода',
        trialDescription: 'Попробуйте все функции бесплатно в течение 3 дней. Банковская карта не требуется.',
        trialRequest: 'Начать с бесплатного пробного периода',
        trialEnabled: 'Активирован',
        trialDisabled: 'Нет',
        trialStatus: 'Пробный период',

        // Form Labels & Captions
        registerCaption: 'Создать аккаунт',
        emailCaption: 'Электронная почта',
        passwordCaption: 'Пароль',
        emailRequired: 'Требуется указать электронную почту',
        emailInvalid: 'Пожалуйста, введите корректный адрес электронной почты',
        passwordRequired: 'Требуется указать пароль',

        // Navigation & Buttons
        continue: 'Продолжить',
        back: 'Назад',
        processing: 'Обработка...',
        completeRegistration: 'Завершить регистрацию',

        // Confirmation
        confirmDetails: 'Подтвердите ваши данные',
        email: 'Электронная почта',

        // Authentication
        alreadyregisteredCaption: 'Уже есть аккаунт?',
        signinCaption: 'Войти',

        // Errors
        errorTitle: 'Произошла ошибка',
        formValidationError: 'Пожалуйста, проверьте введенные данные',
        recaptchaRequired: 'Пожалуйста, пройдите проверку reCAPTCHA',
        recaptchaLoadError: 'Не удалось загрузить reCAPTCHA',
        recaptchaVerificationError: 'Ошибка проверки reCAPTCHA',
        plansLoadError: 'Не удалось загрузить планы подписки',
        registrationError: 'Ошибка регистрации. Пожалуйста, попробуйте снова.',
        networkError: 'Ошибка сетевого подключения. Пожалуйста, проверьте подключение к интернету.',

        // Success Messages
        registrationSuccess: 'Регистрация успешна! Перенаправление в приложение...',

        // Subscription Info
        subscriptionRequired: 'Требуется подписка',
        subscriptionRequiredDesc: 'Для доступа ко всем функциям приложения требуется подписка',

      }
    }
  }


const i18n = createI18n({
  legacy: false,
  locale: 'ru', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: messages
})

export default i18n;
