<script>
  import Header from '@/components/HeaderComp'
  import Footer from '@/components/FooterComp'
  import { useRoute } from 'vue-router'
  import { computed, markRaw } from 'vue'
  import MainLayout from './layouts/MainLayout.vue'
  import BlankLayout from './layouts/BlankLayout.vue'

  export default {
    name: 'app',
    data () {
      return {
        isAuthenticated: false
      }
    },
    components: {
      'app-header':Header,
      'app-footer':Footer,
        MainLayout,
        BlankLayout
    },
    setup() {
      const route = useRoute()

    const layout = computed(() => {
      // Get the layout from the route meta, default to MainLayout
      return markRaw(route.meta.layout ? route.meta.layout : MainLayout)
    })

      return {
        layout
      }
    }
  }
</script>

<template>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </component>
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins');

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}


</style>
