<template>
    <div v-html="xmlContent" class="sitemap-xml"></div>
  </template>

  <script>
  import { ref, onMounted } from 'vue';
  import { routes } from '@/router/index'
  export default {
    name: 'SitemapXML',
    props: {
      baseUrl: {
        type: String,
        required: true,
        default: 'https://artmandala.live'
      }
    },
    setup(props) {
      const xmlContent = ref('');

      // Define static routes that should be included in the sitemap
      const generateSitemapXML = () => {
        const today = new Date().toISOString();
        let xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
        xml += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';

        // Add static routes to sitemap
        routes.forEach(route => {
          xml += '  <url>\n';
          xml += `    <loc>${props.baseUrl}${route.path}</loc>\n`;
          xml += `    <lastmod>${today}</lastmod>\n`;
          xml += `    <changefreq>${route.changefreq}</changefreq>\n`;
          xml += `    <priority>${route.priority}</priority>\n`;
          xml += '  </url>\n';
        });

        xml += '</urlset>';
        return xml;
      };

      onMounted(() => {
        // Generate XML content
        const xml = generateSitemapXML();

        // Escape HTML entities and add formatting
        xmlContent.value = xml
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#039;')
          .replace(/\n/g, '<br>')
          .replace(/ {2}/g, '&nbsp;&nbsp;');
      });

      return {
        xmlContent
      };
    }
  };
  </script>

  <style scoped>
  .sitemap-xml {
    font-family: monospace;
    white-space: pre;
    background: #f5f5f5;
    padding: 20px;
    margin: 20px;
    border-radius: 4px;
  }
  </style>
