<template>
  <div class="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 py-12">
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Enhanced Subscription Alert Banner -->
      <div
        v-if="!userProfile.subscription_status"
        class="mb-8 transform transition-all duration-300 hover:scale-105"
        @mouseenter="isHovered = true"
        @mouseleave="isHovered = false"
      >
        <div class="relative overflow-hidden rounded-xl bg-gradient-to-r from-violet-600 via-purple-600 to-indigo-600 p-[2px]">
          <div class="relative rounded-xl bg-gradient-to-r from-violet-600 via-purple-600 to-indigo-600">
            <div class="px-6 py-8">
              <div class="flex flex-col md:flex-row items-center justify-between gap-6">
                <div class="flex items-center space-x-4">
                  <div class="flex h-12 w-12 items-center justify-center rounded-full bg-white/10">
                    <span class="text-2xl">⚡️</span>
                  </div>
                  <div>
                    <h3 class="text-xl font-bold text-white">
                      {{ $t('message.unlockAllFeatures') }}
                    </h3>
                    <p class="text-indigo-200">
                      {{ $t('message.limitedFeatures') }} ✨
                    </p>
                  </div>
                </div>
                <router-link
                  to="/payment"
                  class="group relative overflow-hidden rounded-lg bg-white px-6 py-3 text-indigo-600 shadow-md transition-all duration-300 hover:shadow-lg hover:scale-105"
                >
                  <div class="absolute inset-0 bg-gradient-to-r from-violet-400 to-indigo-400 opacity-0 transition-opacity group-hover:opacity-10" />
                  <span class="flex items-center font-semibold">
                    {{ $t('message.upgradeNow') }} 🚀
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Profile Card with Enhanced Design -->
      <div class="bg-white rounded-2xl shadow-xl overflow-hidden border border-indigo-50">
        <!-- Loading State -->
        <div v-if="isLoading" class="p-8 flex justify-center">
          <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
        </div>

        <!-- Error Messages -->
        <div v-if="errors.length > 0" class="p-4 bg-red-100 border border-red-400 text-red-700" role="alert">
          <strong class="font-bold">{{ $t('message.errorTitle') }}</strong>
          <ul class="mt-1">
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>

        <!-- Profile Content -->
        <div v-if="!isLoading" class="divide-y divide-gray-100">
          <!-- Header Section -->
          <div class="p-8 bg-gradient-to-r from-indigo-500/5 via-purple-500/5 to-pink-500/5">
            <div class="flex flex-col sm:flex-row items-center gap-6">
              <div class="relative group">
                <div class="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full opacity-50 group-hover:opacity-100 blur transition duration-300"></div>
                <img
                  :src="userProfile.avatar || '/src/assets/images/person-3.png'"
                  :alt="userProfile.username"
                  class="relative h-24 w-24 rounded-full object-cover border-4 border-white shadow-lg"
                />
              </div>
              <div>
                <h1 class="text-2xl font-bold text-gray-900">
                  {{ userProfile.first_name || userProfile.username }} ✌️
                </h1>
                <p class="text-sm text-gray-500">
                  {{ $t('message.joinedCaption') }} {{ formatDate(userProfile.joined_date) }} 🎉
                </p>
              </div>
            </div>
          </div>

          <!-- Subscription Status with Enhanced Visual Feedback -->
          <div class="px-8 py-6">
            <h2 class="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
              {{ $t('message.subscriptionStatus') }}
              <span v-if="userProfile.subscription_status">💫</span>
              <span v-else>🔒</span>
            </h2>

            <div v-if="userProfile.subscription_status && userProfile.subscription_details"
                 class="bg-gradient-to-r from-green-50 to-emerald-50 rounded-xl p-6 border border-green-100 shadow-sm">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p class="text-sm text-gray-600">{{ $t('message.subscriptionPlan') }}</p>
                  <p class="font-semibold text-gray-900">{{ userProfile.subscription_details.plan_name }}</p>
                </div>
                <div>
                  <p class="text-sm text-gray-600">{{ $t('message.status') }}</p>
                  <span v-if="userProfile.subscription_details.status === 'active'" class="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium"
                        :class="getStatusClass">
                    {{ $t('message.activeStatus') }} ✨
                  </span>
                  <span v-else-if="userProfile.subscription_details.status === 'pending'" class="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium"
                        :class="getStatusClass">
                    {{ $t('message.pendingStatus') }} ✨
                  </span>
                  <span v-else-if="userProfile.subscription_details.status === 'trial'" class="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium"
                        :class="getStatusClass">
                    {{ $t('message.trialStatus') }} ✨
                  </span>
                </div>
                <div>
                  <p class="text-sm text-gray-600">{{ $t('message.validUntil') }}</p>
                  <p class="font-semibold text-gray-900">
                    {{ formatDate(userProfile.subscription_details.end_date) }}
                  </p>
                </div>
                <div>
                  <p class="text-sm text-gray-600">{{ $t('message.daysRemaining') }}</p>
                  <p class="font-semibold text-gray-900">
                    {{ userProfile.subscription_details.days_remaining }} {{ $t('message.days') }} ⏳
                  </p>
                </div>
              </div>
            </div>

            <div v-else
                 class="bg-gradient-to-r from-gray-50 to-indigo-50 rounded-xl p-8 border border-indigo-100 shadow-sm text-center">
              <div class="mb-6">
                <span class="inline-block text-4xl mb-2">🎯</span>
                <h3 class="text-lg font-semibold text-gray-900">
                  {{ $t('message.noActiveSubscription') }}
                </h3>
                <p class="mt-2 text-gray-600">
                  {{ $t('message.limitedFeatures') }}
                </p>
              </div>
              <router-link
                to="/payment"
                class="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-500 hover:to-purple-500 transform transition-all duration-300 hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {{ $t('message.upgradeNow') }} ⭐️
              </router-link>
            </div>
          </div>

          <!-- Profile Information with Subtle Gradients -->
          <div class="px-8 py-6 bg-gradient-to-r from-purple-50/50 via-transparent to-transparent">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h2 class="text-lg font-semibold text-gray-900 flex items-center gap-2">
                  {{ $t('message.userProfileCaption') }} 👤
                </h2>
                <dl class="mt-4 space-y-4">
                  <div>
                    <dt class="text-sm font-medium text-gray-500">{{ $t('message.usernameCaption') }}</dt>
                    <dd class="mt-1 text-sm text-gray-900">{{ userProfile.username }}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">{{ $t('message.emailCaption') }}</dt>
                    <dd class="mt-1 text-sm text-gray-900">{{ userProfile.email }}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">{{ $t('message.locationCaption') }}</dt>
                    <dd class="mt-1 text-sm text-gray-900">{{ userProfile.location || $t('message.notSpecified') }}</dd>
                  </div>
                </dl>
              </div>
              <div>
                <h2 class="text-lg font-semibold text-gray-900 flex items-center gap-2">
                  {{ $t('message.bioCaption') }} 📝
                </h2>
                <p class="mt-4 text-sm text-gray-600">
                  {{ userProfile.bio || $t('message.noBio') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useAuthStore } from '@/stores/user'
import axiosInstance from '@/axios/axios'

export default {
  name: 'UserProfile',
  setup() {
    const userStore = useAuthStore()
    const isLoading = ref(true)
    const errors = ref([])
    const isHovered = ref(false)
    const userProfile = ref({
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      bio: '',
      location: '',
      joined_date: '',
      avatar: '',
      subscription_status: null,
      subscription_details: null
    })

    const getStatusClass = computed(() => {
      if (!userProfile.value.subscription_details) return ''
      if (userProfile.value.subscription_details.is_trial) {
        return 'bg-blue-100 text-blue-800'
      }
      return userProfile.value.subscription_details.status === 'active'
        ? 'bg-green-100 text-green-800'
        : 'bg-yellow-100 text-yellow-800'
    })

    // const getStatusText = computed(() => {
    //   if (!userProfile.value.subscription_details) return ''
    //   if (userProfile.value.subscription_details.is_trial) {
    //     return $t('message.trialStatus')
    //   }
    //   return userProfile.value.subscription_details.status === 'active'
    //     ? i18n.t('message.activeStatus')
    //     : i18n.t('message.pendingStatus')
    // })

    const formatDate = (date) => {
      if (!date) return ''
      return new Date(date).toLocaleDateString()
    }

    const fetchUserProfile = async () => {
      try {
        isLoading.value = true
        const response = await axiosInstance.get('/api/auth/userinfo/')
        userProfile.value = response.data
        userStore.setUser(response.data)
      } catch (error) {
        console.error('Error fetching user profile:', error)
        errors.value = [error.response?.data?.detail || $t('message.profileLoadError')]
      } finally {
        isLoading.value = false
      }
    }

    onMounted(() => {
      fetchUserProfile()
    })

    return {
      isLoading,
      errors,
      isHovered,
      userProfile,
      getStatusClass,
      formatDate
    }
  }
}
</script>
