import axios from 'axios';
import jwtDecode from 'jwt-decode';

const API_URL = import.meta.env.VITE_ROOTAPI || "http://localhost:8000";

const SUBSCRIPTION_CHECK_EXEMPT_PATHS = [
  '/api/auth/token/refresh/',
  '/api/auth/token/',
  '/api/auth/register/',
  '/api/auth/verify-recaptcha/',
  '/api/free/plans',
  '/payment/',
  '/api/webhook/'
];

function isTokenExpired(token) {
  if (!token) return true;
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    const isExpired = decoded.exp <= currentTime;
    console.log('Token expiration check:', {
      expTime: new Date(decoded.exp * 1000).toISOString(),
      currentTime: new Date(currentTime * 1000).toISOString(),
      isExpired
    });
    return isExpired;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
}

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
  refreshSubscribers.forEach(callback => callback(token));
  refreshSubscribers = [];
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

let authStore = null;
let router = null;

export function initializeAxiosInterceptors(store, vueRouter) {
  authStore = store;
  router = vueRouter;

  // Request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      // Skip token check for token refresh requests
      if (config.url === '/api/auth/token/refresh/') {
        const refreshToken = authStore.getRefreshToken;
        config.data = {
          refresh: refreshToken
        };
        return config;
      }

      let token = authStore.getToken;

      // Check if token exists and is expired
      if (token && isTokenExpired(token)) {
        console.log('Token expired, attempting refresh');

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const refreshToken = authStore.getRefreshToken;
            const response = await axios.post(`${API_URL}/api/auth/token/refresh/`, {
              refresh: refreshToken
            });

            const newToken = response.data.access;
            authStore.setToken(newToken);

            isRefreshing = false;
            onRefreshed(newToken);
            token = newToken;
          } catch (error) {
            isRefreshing = false;
            authStore.clearAuth();
            router.push('/signin');
            return Promise.reject(error);
          }
        } else {
          const newToken = await new Promise(resolve => {
            refreshSubscribers.push(resolve);
          });
          token = newToken;
        }
      }

      // Add token to request header if it exists
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;

      // Handle 401 Unauthorized errors
      if (error.response?.status === 401 && !originalRequest._retry) {
        // If the refresh token request fails, logout
        if (originalRequest.url === '/api/auth/token/refresh/') {
          authStore.clearAuth();
          router.push('/signin');
          return Promise.reject(error);
        }

        // Try to refresh the token
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const refreshToken = authStore.getRefreshToken;
            const response = await axios.post(`${API_URL}/api/auth/token/refresh/`, {
              refresh: refreshToken
            });

            const newToken = response.data.access;
            authStore.setToken(newToken);

            isRefreshing = false;
            onRefreshed(newToken);

            // Retry original request with new token
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return axiosInstance(originalRequest);
          } catch (refreshError) {
            isRefreshing = false;
            authStore.clearAuth();
            router.push('/signin');
            return Promise.reject(refreshError);
          }
        } else {
          // Wait for the token to be refreshed
          try {
            const newToken = await new Promise(resolve => {
              refreshSubscribers.push(resolve);
            });
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return axiosInstance(originalRequest);
          } catch (error) {
            return Promise.reject(error);
          }
        }
      }

      return Promise.reject(error);
    }
  );
}

export default axiosInstance;
