<template>
    <div class="blank-layout">
      <slot></slot>
    </div>
  </template>

  <script>
  export default {
    name: "BlankLayout"
  }
  </script>
