<template>
  <div class="min-h-screen bg-gray-50 py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-12">
        <h1 class="text-4xl font-bold text-gray-900 mb-4">Блог о Мандалах Сети Света</h1>
        <p class="text-xl text-gray-600">Исследуйте мир исцеляющего искусства и самопознания</p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div
          v-for="post in blogPosts"
          :key="post.id"
          class="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
          @click="navigateToPost(post.slug)"
        >
          <!-- Card Header -->
          <div class="w-full">
            <img
              :src="post.image"
              :alt="post.title"
              class="w-full h-48 object-cover rounded-t-lg"
            />
          </div>

          <!-- Card Content -->
          <div class="p-6">
            <div class="flex items-center space-x-2 text-sm text-gray-500 mb-2">
              <span>{{ post.category }}</span>
              <span>•</span>
              <span>{{ post.readTime }}</span>
            </div>
            <h2 class="text-xl font-semibold mb-2">{{ post.title }}</h2>
            <p class="text-gray-600">{{ post.description }}</p>
          </div>

          <!-- Card Footer -->
          <div class="px-6 py-4 border-t">
            <div class="flex justify-between items-center w-full">
              <span class="text-sm text-gray-500">{{ post.date }}</span>
              <span class="text-blue-600 hover:text-blue-800">Читать далее →</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useHead } from 'unhead'
const router = useRouter()

const blogPosts = ref([
  {
    id: 1,
    title: "Отличие методики «Мандалы Сети Света»",
    description: "Узнайте, чем уникальна методика Мандалы Сети Света и как она отличается от других арт-терапевтических техник",
    image: "/src/assets/images/article-girl.png",
    date: "16 ноября 2024",
    readTime: "5 мин",
    category: "Арт-терапия",
    slug: "/article"
  }
  // More blog posts can be added here
])

const navigateToPost = (slug) => {
  router.push(slug)
}
// SEO metadata

    useHead({
      title: 'Блог о Мандалах Сети Света | АртМандала',
      meta: [
        {
          name: 'description',
          content: 'Познавательные статьи о методике Мандалы Сети Света, арт-терапии и духовном развитии'
        },
        {
          name: 'keywords',
          content: 'мандалы сети света, блог, арт-терапия, духовное развитие, медитация, самопознание'
        },
        {
          property: 'og:title',
          content: 'Блог о Мандалах Сети Света | АртМандала'
        },
        {
          property: 'og:description',
          content: 'Познавательные статьи о методике Мандалы Сети Света, арт-терапии и духовном развитии'
        },
        {
          property: 'og:type',
          content: 'website'
        }
      ]
    })
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
</style>
