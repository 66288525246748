import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import { createPinia } from "pinia";
import { initializeAxiosInterceptors } from './axios/axios';
import { useAuthStore } from './stores/user';
import i18n from '@/i18n/index.js'
import './index.css'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createApolloProvider } from '@vue/apollo-option'
import { createHead } from 'unhead'
import * as Sentry from "@sentry/vue";


const API_URL = typeof import.meta.env.VITE_ROOTAPI === "undefined"
    ? "http://service:8000"
    : import.meta.env.VITE_ROOTAPI;
const httpLink = createHttpLink({
    uri: API_URL+'/graphql',
  })
const cache = new InMemoryCache()
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})
const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

let app = createApp(App).use(createPinia()).use(router).use(i18n)

//--> Sentry for error logging init
Sentry.init({
  app,
  dsn: "https://3a09824ba5b69ef3efdda0ecad41987e@o4508445811802112.ingest.de.sentry.io/4508445813702736",
  integrations: [
],
});
//<-- Sentry for error logging init
if (import.meta.env.VITE_PAYLOAD_REGEX) {
    app.provide('payloadRegex', new RegExp(import.meta.env.VITE_PAYLOAD_REGEX));
    app.provide('payloadRegexG', new RegExp(import.meta.env.VITE_PAYLOAD_REGEX, 'g'));

} else {
    console.log("VITE_PAYLOAD_REGEX not found or empty");
}
const userStore = useAuthStore();
initializeAxiosInterceptors(userStore, router);
app.use(apolloProvider)
app.use(createHead())
app.mount('#app')
