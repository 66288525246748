<template>
  <div class="app-container scroll-smooth">
    <HeaderComp />
    <main class="content mt-24">
      <slot></slot>
    </main>
    <FooterComp class="footer" />
  </div>
</template>

<script>
import HeaderComp from '@/components/HeaderComp.vue'
import FooterComp from '@/components/FooterComp.vue'

export default {
  name: "MainLayout",
  components: {
    HeaderComp,
    FooterComp
  },
  data() {
    return {
      isAuthenticated: false
    }
  }
}
</script>
